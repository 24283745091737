import axios from "axios";

export const getAllProducts = async (limit = 32, page = 1) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/products/?limit=${limit}&page=${page}`
    );
    if (response.data.success) {
      return {
        products: response.data.data.products,
        total: response.data.data.total,
        pages: response.data.data.pages,
      };
    } else {
      throw new Error("Failed to fetch products");
    }
  } catch (error) {
    console.error("Error fetching products:", error);
    return { products: [], total: 0, pages: 0 };
  }
};
