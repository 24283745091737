import React from "react";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import "./Cart.css";
import useStore from "../stores/store";
import { useNavigate } from "react-router-dom";
import { setProductsToCart } from "../scripts/setProductsToCart";

export default function Cart() {
  const userCart = useStore((state) => state.userCart);
  const addToCart = useStore((state) => state.addToCart);
  const removeFromCart = useStore((state) => state.removeFromCart);
  const needLogin = useStore((state) => state.needLogin);
  const setNeedLogin = useStore((state) => state.setNeedLogin);
  const userAccessToken = useStore((state) => state.userAccessToken);
  const setUserAccessToken = useStore((state) => state.setUserAccessToken);
  const navigate = useNavigate();

  const increaseQuantity = (id, variantId) => {
    if (variantId) {
      const product = userCart.find(
        (item) => item.id === id && item.variantId === variantId
      );
      if (product) {
        addToCart(product);
      }
    } else {
      const product = userCart.find((item) => item.id === id);
      if (product) {
        addToCart(product);
      }
    }
  };

  const decreaseQuantity = (id, variantId) => {
    if (variantId) {
      const product = userCart.find(
        (item) => item.id === id && item.variantId === variantId
      );
      if (product) {
        removeFromCart(product);
      }
    } else {
      const product = userCart.find((item) => item.id === id);
      if (product) {
        removeFromCart(product);
      }
    }
  };

  const addLocalCartToServer = async () => {
    if (userCart.length > 0) {
      try {
        const response = await setProductsToCart(userCart, userAccessToken);
        if (response.status === 200) {
          navigate("/checkout");
        } else if (
          response.status === 400 &&
          response.message === "Invalid token."
        ) {
          setNeedLogin(true);
          setUserAccessToken(null);
          navigate("/login");
        } else if (
          response.status === 404 &&
          response.message === "User not found."
        ) {
          setNeedLogin(true);
          setUserAccessToken(null);
          navigate("/login");
        }
      } catch (error) {
        console.error("Failed to add products to cart:", error);
      }
    }
  };

  const checkoutHandler = async () => {
    if (needLogin || !userAccessToken) {
      navigate("/login");
    } else {
      addLocalCartToServer();
    }
  };

  return (
    <>
      <Navbar />
      <div className="container-CartPage">
        <div className="main-box-CartPage">
          <p className="title-box-CartPage">سبد خرید</p>
          <div className="divider-top-CartPage"></div>
          {userCart.length === 0 ? (
            <div className="empty-cart-CartPage">
              <p className="empty-cart-text-CartPage">سبد خرید شما خالی است</p>
            </div>
          ) : (
            <div className="table-container-CartPage">
              <table className="table-CartPage">
                <thead>
                  <tr>
                    <th>تنظیمات</th>
                    <th>تعداد</th>
                    <th>قیمت کل</th>
                    <th>قیمت</th>
                    <th>نام</th>
                    <th>تصویر</th>
                  </tr>
                </thead>
                <tbody>
                  {userCart.map((item) => (
                    <tr key={item.id}>
                      <td>
                        <div style={{ display: "flex" }}>
                          <button
                            onClick={() =>
                              decreaseQuantity(item.id, item.variantId)
                            }
                            className="btn-quantity-CartPage"
                          >
                            -
                          </button>
                          <button
                            onClick={() =>
                              increaseQuantity(item.id, item.variantId)
                            }
                            className="btn-quantity-CartPage"
                          >
                            +
                          </button>
                        </div>
                      </td>
                      <td className="td-CartPage">{item.quantity}</td>
                      <td className="td-CartPage">
                        {(item.productPrice * item.quantity).toLocaleString()}{" "}
                        تومان
                      </td>
                      <td className="td-CartPage">
                        {item.productPrice.toLocaleString()} تومان
                      </td>
                      <td>
                        {item.name}
                        <br />
                        {item.variantName}
                      </td>
                      <td>
                        <img
                          src={`${process.env.REACT_APP_SERVER_URL}/api${item.imageObject.url}`}
                          alt={item.imageObject.altText}
                          className="image-product-CartPage"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          <div className="divider-top-CartPage"></div>
          <div className="footer-CartPage">
            <p className="footer-text-CartPage">
              کالاهای موجود در سبد خرید، هنوز ثبت و رزرو نشده اند. برای ثبت
              سفارش روی دکمه تسویه حساب کلیک کنید.
            </p>
            <p className="footer-total-text-CartPage">
              جمع کل:{" "}
              {userCart
                .reduce(
                  (total, item) => total + item.productPrice * item.quantity,
                  0
                )
                .toLocaleString()}{" "}
              تومان
            </p>
            <button
              className="pay-btn-CartPage"
              style={{
                background:
                  "linear-gradient(to right, rgb(255, 170, 34), rgb(255, 119, 0))",
              }}
              onClick={checkoutHandler}
            >
              <p className="new-price-BuyBoxProduct">تسویه حساب</p>
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
