import React, { useState, useEffect } from "react";
import Footer from "../components/footer/Footer";
import NavBar from "../components/navbar/Navbar";
import "./Login.css";
import useStore from "../stores/store";
import { sendCode } from "../scripts/sendCode";
import { verifyCode } from "../scripts/verifyCode";
import { useNavigate } from "react-router-dom";
import { getUserDetails } from "../scripts/getUserDetails";
import ReCAPTCHA from "react-google-recaptcha";

export default function Login() {
  const [codeSent, setCodeSent] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [code, setCode] = useState(["", "", "", ""]);
  const [timer, setTimer] = useState(120);
  const [error, setError] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const setNeedLogin = useStore((state) => state.setNeedLogin);
  const setUserAccessToken = useStore((state) => state.setUserAccessToken);
  const setIsAdmin = useStore((state) => state.setIsAdmin);
  const navigate = useNavigate();

  useEffect(() => {
    let interval;
    if (codeSent && timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [codeSent, timer]);

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    setPhoneNumber(value);
  };

  const sendCodeHandler = async () => {
    if (!recaptchaToken) {
      setError("لطفا ریکپچا را تکمیل کنید");
      return;
    }
    if (/^09\d{9}$/.test(phoneNumber)) {
      const response = await sendCode(phoneNumber, recaptchaToken);
      if (response.success) {
        setCodeSent(true);
        setTimer(120);
      } else {
        setError(response.message);
      }
    } else {
      setError("شماره تلفن صحیح نیست");
    }
  };

  const handleCodeChange = (index, value) => {
    if (/^\d{0,1}$/.test(value)) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      if (value) {
        if (index < code.length - 1) {
          document.getElementById(`code-input-${index + 1}-Login`).focus();
        }
      } else {
        if (index > 0) {
          document.getElementById(`code-input-${index - 1}-Login`).focus();
        }
      }
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && code[index] === "") {
      if (index > 0) {
        document.getElementById(`code-input-${index - 1}-Login`).focus();
      }
    }
  };

  const checkCodeHandler = async () => {
    const verificationCode = code.join("");
    const response = await verifyCode(phoneNumber, verificationCode);
    if (response.success) {
      setUserAccessToken(response.data["access-token"]);
      setNeedLogin(false);
      navigate("/dashboard");
      const userDetails = await getUserDetails(response.data["access-token"]);
      if (userDetails.data.isAdmin) {
        setIsAdmin(true);
      }
    } else {
      setError(response.message);
    }
  };

  const resendCodeHandler = async () => {
    const response = await sendCode(phoneNumber);
    if (response.success) {
      setTimer(120);
    } else {
      setError(response.message);
    }
  };

  const onRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  return (
    <>
      <NavBar />
      <div className="container-Login">
        {!codeSent ? (
          <div className="box-Login">
            <img
              className="image-Login"
              src="/assets/images/login-image.png"
              alt="Login page"
            />
            <p className="text-Login">
              جهت ورود به حساب کاربری لطفا شماره تلفن همراه خود را وارد کنید
            </p>
            <input
              type="tel"
              className="input-Login"
              placeholder="09127642144"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              minLength={11}
              maxLength={11}
            />
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
              onChange={onRecaptchaChange}
            />
            {error && <p className="error-Login">{error}</p>}
            <button className="button-Login" onClick={sendCodeHandler}>
              ارسال کد ورود
            </button>
          </div>
        ) : (
          <div className="box-Login">
            <img
              className="image-Login"
              src="/assets/images/login-image.png"
              alt="Verify code page"
            />
            <p className="text-Login">کد ارسال شده را وارد نمایید</p>
            <div className="input-box-Login">
              {code.map((digit, index) => (
                <input
                  key={index}
                  type="tel"
                  className="input-code-Login"
                  id={`code-input-${index}-Login`}
                  value={digit}
                  onChange={(e) => handleCodeChange(index, e.target.value)}
                  onKeyDown={(e) => handleKeyDown(index, e)}
                  maxLength={1}
                />
              ))}
            </div>
            <button className="button-code-Login" onClick={checkCodeHandler}>
              ورود
            </button>
            {timer > 0 ? (
              <p className="text-send-code-again-Login">
                ارسال مجدد کد در {timer} ثانیه دیگر
              </p>
            ) : (
              <button className="button-Login" onClick={resendCodeHandler}>
                ارسال مجدد کد
              </button>
            )}
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}
