import React, { useState, useEffect } from "react";
import {
  uploadImage,
  fetchImages,
  updateImageDetails,
  deleteImage,
} from "../../scripts/admin/adminImages";
import useStore from "../../stores/store";
import "./AdminImages.css"; // Assuming you will create a CSS file for styling
import { toast } from "react-toastify";

export default function AdminImages() {
  const userAccessToken = useStore((state) => state.userAccessToken);
  const [images, setImages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [newImage, setNewImage] = useState({
    file: null,
    name: "",
    altText: "",
  });
  const [editImage, setEditImage] = useState(null);

  useEffect(() => {
    const fetchImagesData = async () => {
      const response = await fetchImages(userAccessToken, currentPage);
      if (response.success) {
        setImages(response.data.images);
        setTotalPages(response.data.totalPages);
      } else {
        toast.error("خطا در دریافت تصاویر");
      }
    };

    fetchImagesData();
  }, [userAccessToken, currentPage]);

  const handleAddImage = async () => {
    const response = await uploadImage(userAccessToken, newImage);
    if (response.success) {
      setImages([...images, response.data]);
      setShowModal(false);
      setNewImage({ file: null, name: "", altText: "" });
    } else {
      toast.error("خطا در آپلود تصویر");
    }
  };

  const handleUpdateImage = async () => {
    const updatedImageData = {
      altText: editImage.altText,
      newFileName: editImage.name,
    };
    const response = await updateImageDetails(
      userAccessToken,
      editImage._id,
      updatedImageData
    );
    if (response.success) {
      setImages(
        images.map((image) =>
          image._id === editImage._id ? response.data : image
        )
      );
      setEditImage(null);
    } else {
      toast.error("خطا در ویرایش تصویر");
    }
  };

  const handleDeleteImage = async (id) => {
    const response = await deleteImage(userAccessToken, id);
    if (response.success) {
      setImages(images.filter((image) => image._id !== id));
    } else {
      toast.error("خطا در حذف تصویر");
    }
  };

  const handleCopyId = (id) => {
    navigator.clipboard.writeText(id).then(
      () => {
        toast.success("آیدی کپی شد");
      },
      (err) => {
        toast.error("خطا در کپی آیدی");
      }
    );
  };

  const extractImageName = (url) => {
    const parts = url.split("/");
    const fileName = parts[parts.length - 1];
    return fileName.split(".")[0];
  };

  return (
    <div className="admin-images-AdminPage">
      <div className="header-AdminPage">
        <h2>تصاویر</h2>
        <button
          className="new-image-btn-AdminPage"
          onClick={() => setShowModal(true)}
        >
          تصویر جدید
        </button>
      </div>
      <div className="images-grid-AdminPage">
        {images.map((image) => (
          <div key={image._id} className="image-box-AdminPage">
            <img
              src={`${process.env.REACT_APP_SERVER_URL}/api${image.url}`}
              alt={`${image.altText}`}
            />
            <div className="image-overlay-AdminPage">
              <button
                className="delete-btn-AdminPage"
                style={{
                  backgroundColor: "orange",
                }}
                onClick={() => handleCopyId(image._id)}
              >
                کپی آیدی
              </button>
              <button
                className="details-btn-AdminPage"
                onClick={() =>
                  setEditImage({ ...image, name: extractImageName(image.url) })
                }
              >
                جزئیات
              </button>
              <button
                className="delete-btn-AdminPage"
                onClick={() => handleDeleteImage(image._id)}
              >
                حذف
              </button>
            </div>
          </div>
        ))}
      </div>
      <div className="pagination-AdminPage">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            className={`page-btn-AdminPage ${
              currentPage === index + 1 ? "active" : ""
            }`}
            onClick={() => setCurrentPage(index + 1)}
          >
            {index + 1}
          </button>
        ))}
      </div>
      {showModal && (
        <div className="modal-AdminPage">
          <div className="modal-content-AdminPage">
            <h3>افزودن تصویر جدید</h3>
            <label style={{ direction: "rtl" }}>
              انتخاب فایل تصویر:
              <input
                type="file"
                onChange={(e) =>
                  setNewImage({ ...newImage, file: e.target.files[0] })
                }
              />
            </label>
            <label style={{ direction: "rtl" }}>
              نام تصویر:
              <input
                type="text"
                value={newImage.name}
                onChange={(e) =>
                  setNewImage({ ...newImage, name: e.target.value })
                }
              />
            </label>
            <label style={{ direction: "rtl" }}>
              توضیحات تصویر:
              <input
                type="text"
                value={newImage.altText}
                onChange={(e) =>
                  setNewImage({ ...newImage, altText: e.target.value })
                }
              />
            </label>
            <button
              style={{ fontFamily: "Vazir-FD-Wol" }}
              onClick={handleAddImage}
            >
              افزودن
            </button>
            <button
              style={{ fontFamily: "Vazir-FD-Wol" }}
              onClick={() => setShowModal(false)}
            >
              لغو
            </button>
          </div>
        </div>
      )}
      {editImage && (
        <div className="modal-AdminPage" style={{ direction: "rtl" }}>
          <div className="modal-content-AdminPage">
            <h3>ویرایش تصویر</h3>
            <p>آیدی تصویر: {editImage._id}</p>
            <p>
              آدرس تصویر:{" "}
              {`${process.env.REACT_APP_SERVER_URL}/api${editImage.url}`}
            </p>
            <label style={{ direction: "rtl" }}>
              نام تصویر:
              <input
                type="text"
                value={editImage.name}
                onChange={(e) =>
                  setEditImage({ ...editImage, name: e.target.value })
                }
              />
            </label>
            <label style={{ direction: "rtl" }}>
              توضیحات تصویر:
              <input
                type="text"
                value={editImage.altText}
                onChange={(e) =>
                  setEditImage({ ...editImage, altText: e.target.value })
                }
              />
            </label>
            <button
              style={{ fontFamily: "Vazir-FD-Wol" }}
              onClick={handleUpdateImage}
            >
              ذخیره
            </button>
            <button
              style={{ fontFamily: "Vazir-FD-Wol" }}
              onClick={() => setEditImage(null)}
            >
              لغو
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
