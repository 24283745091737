import React, { useEffect, useState } from "react";
import "./Checkout.css";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import useStore from "../stores/store";
import { getAddressesList } from "../scripts/getAddressesList";
import { useNavigate } from "react-router-dom";
import { getCartItems } from "../scripts/getCartItems";
import { createOrder } from "../scripts/createOrder";

export default function Checkout() {
  const [selectedAddressId, setSelectedAddressId] = useState(null);
  const [selectedSendMethod, setSelectedSendMethod] = useState("پست پیشتاز");
  const userAccessToken = useStore((state) => state.userAccessToken);
  const setUserAccessToken = useStore((state) => state.setUserAccessToken);
  const needLogin = useStore((state) => state.needLogin);
  const setNeedLogin = useStore((state) => state.setNeedLogin);
  const userCart = useStore((state) => state.userCart);
  const [addressesList, setAddressesList] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalSalePrice, setTotalSalePrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [totalOverallPrice, setTotalOverallPrice] = useState(0);
  const navigate = useNavigate();
  const updateCart = useStore((state) => state.updateCart);

  const addresses = addressesList.map((address) => ({
    id: address.addressId,
    name: `${address.name} ${address.lastName}`,
    address: `${address.state} ، ${address.city} ، ${address.streetAddress}`,
    postalCode: address.postalCode,
    mobile: address.phoneNumber,
  }));

  if (addresses.length > 0 && selectedAddressId === null) {
    setSelectedAddressId(addresses[0].id);
  }

  const handleSelectAddress = (id) => {
    setSelectedAddressId(id);
    const selectedAddress = addresses.find((address) => address.id === id);
    if (selectedAddress && selectedAddress.address.includes("تهران")) {
      setSelectedSendMethod("پیک");
    } else {
      setSelectedSendMethod("پست پیشتاز");
    }
  };

  const handleSendMethodChange = (method) => {
    const selectedAddress = addresses.find(
      (address) => address.id === selectedAddressId
    );
    if (
      method === "پیک" &&
      selectedAddress &&
      !selectedAddress.address.includes("تهران")
    ) {
      return; // Do nothing if the address is not in Tehran
    }
    setSelectedSendMethod(method);
    const deliveryPrice = method === "پست پیشتاز" ? 0 : 150000;
    setTotalOverallPrice(totalSalePrice + deliveryPrice);
  };

  const handleGetAddressesList = async () => {
    try {
      const response = await getAddressesList(userAccessToken);
      if (response.status === 200) {
        setAddressesList(response.data);
      } else if (
        response.status === 400 &&
        response.message === "Invalid token."
      ) {
        setNeedLogin(true);
        setUserAccessToken(null);
        navigate("/login");
      } else if (
        response.status === 404 &&
        response.message === "User not found."
      ) {
        setNeedLogin(true);
        setUserAccessToken(null);
        navigate("/login");
      }
    } catch (error) {
      console.error("Failed to fetch user data:", error);
    }
  };

  const getCartItemsList = async () => {
    try {
      const response = await getCartItems(userAccessToken);
      if (response.status === 200) {
        const items = response.data;

        updateCart(items); // Update local cart with server data

        const totalPrice = items.reduce(
          (total, item) =>
            total +
            (item.useVariant
              ? item.variantSelectedPrice * item.quantity
              : item.price * item.quantity),
          0
        );
        const totalSalePrice = items.reduce(
          (total, item) =>
            total +
            (item.useVariant
              ? (item.variantSelectedSalePrice
                  ? item.variantSelectedSalePrice
                  : item.variantSelectedPrice) * item.quantity
              : (item.salePrice ? item.salePrice : item.price) * item.quantity),
          0
        );
        const discount = totalPrice - totalSalePrice;
        const deliveryPrice = selectedSendMethod === "پست پیشتاز" ? 0 : 150000;
        const totalOverallPrice = totalSalePrice + deliveryPrice;

        setTotalPrice(totalPrice);
        setTotalSalePrice(totalSalePrice);
        setDiscount(discount);
        setTotalOverallPrice(totalOverallPrice);
      } else if (
        response.status === 400 &&
        response.message === "Invalid token."
      ) {
        setNeedLogin(true);
        setUserAccessToken(null);
        navigate("/login");
      } else if (
        response.status === 404 &&
        response.message === "User not found."
      ) {
        setNeedLogin(true);
        setUserAccessToken(null);
        navigate("/login");
      } else if (
        response.status === 404 &&
        response.message === "Cart not found."
      ) {
        navigate("/cart");
      }
    } catch (error) {
      console.error("Failed to fetch user data:", error);
    }
  };

  const makePayment = async () => {
    try {
      const response = await createOrder(
        selectedAddressId,
        selectedSendMethod === "پست پیشتاز" ? "post" : "express",
        userAccessToken
      );
      if (response.status === 201) {
        window.location.href = response.data.paymentUrl;
      } else if (
        response.status === 400 &&
        response.message === "Invalid token."
      ) {
        setNeedLogin(true);
        setUserAccessToken(null);
        navigate("/login");
      } else if (
        response.status === 404 &&
        response.message === "User not found."
      ) {
        setNeedLogin(true);
        setUserAccessToken(null);
        navigate("/login");
      }
    } catch (error) {
      console.error("Failed to fetch user data:", error);
    }
  };

  useEffect(() => {
    if (needLogin || !userAccessToken) {
      navigate("/login");
    } else if (userCart.length === 0) {
      navigate("/cart");
    } else {
      handleGetAddressesList();
      getCartItemsList();
    }
  }, []);

  return (
    <>
      <Navbar />
      <div className="container-Checkout">
        <div className="main-box-Checkout">
          <div className="address-top-box-Checkout">
            <p className="main-box-titles-Checkout">آدرس ارسال</p>
            <button
              className="address-new-btn-Checkout"
              onClick={() => navigate("/dashboard?tab=addresses")}
            >
              <img
                src="/assets/icons/add.png"
                className="image-add-Checkout"
                alt="Add new address"
              />
            </button>
          </div>
          <div className="addresses-container-Checkout">
            {addresses.map((address) => (
              <div
                key={address.id}
                className={`box-Checkout ${
                  selectedAddressId === address.id
                    ? "selected-box-Checkout"
                    : ""
                }`}
                onClick={() => handleSelectAddress(address.id)}
              >
                <div className="top-box-Checkout">
                  <p className="reciver-name-Checkout">{address.name}</p>
                  <p className="address-Checkout">{address.address}</p>
                </div>
                <div className="bottem-box-Checkout">
                  <div className="right-box-Checkout">
                    <div className="right-box-item-Checkout">
                      <img
                        className="icon-Checkout"
                        src="/assets/icons/postbox.png"
                        alt="Postal code"
                      />
                      <p className="label-Checkout">کد پستی:</p>
                      <p className="text-Checkout">{address.postalCode}</p>
                    </div>
                    <div className="right-box-item-Checkout">
                      <img
                        className="icon-Checkout"
                        src="/assets/icons/call.png"
                        alt="Mobile"
                      />
                      <p className="label-Checkout">شماره موبایل:</p>
                      <p className="text-Checkout">{address.mobile}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Shipping Method Section */}
          <p className="main-box-titles-Checkout">روش حمل و نقل</p>
          <div className="send-methods-Checkout">
            <label className="send-method-Checkout">
              <input
                type="checkbox"
                checked={selectedSendMethod === "پست پیشتاز"}
                onChange={() => handleSendMethodChange("پست پیشتاز")}
              />
              <img
                className="send-method-icon-Checkout"
                src="/assets/icons/delivery-car.png"
                alt="پست پیشتاز"
              />
              <p className="send-method-title-Checkout">
                ارسال با پست پیشتاز به سراسر ایران
              </p>
              <p className="send-method-price-Checkout">رایگان</p>
            </label>
            <label className="send-method-Checkout">
              <input
                type="checkbox"
                checked={selectedSendMethod === "پیک"}
                onChange={() => handleSendMethodChange("پیک")}
                disabled={
                  selectedAddressId &&
                  !addresses
                    .find((address) => address.id === selectedAddressId)
                    .address.includes("تهران")
                }
              />
              <img
                className="send-method-icon-Checkout"
                src="/assets/icons/delivery-motor.png"
                alt="پیک"
              />
              <p className="send-method-title-Checkout">
                ارسال با پیک به سراسر تهران
              </p>
              <p className="send-method-price-Checkout">150,000 تومان</p>
            </label>
          </div>

          {/* Discount Code Section */}
          {/* <p className="main-box-titles-Checkout">کد تخفیف</p>
          <div className="discount-code-Checkout">
            <p className="text-discount-code-Checkout">
              کد تخفیف دارید؟ کد تخفیف خود را وارد کنید:{" "}
            </p>
            <input
              type="text"
              className="discount-input-Checkout"
              value={discountCode}
              onChange={(e) => setDiscountCode(e.target.value)}
            />
            <button className="apply-discount-btn-Checkout">اعمال</button>
          </div> */}

          {/* Summary Table Section */}
          <div className="divider-top-Checkout"></div>
          <table className="summary-table-Checkout">
            <tbody>
              <tr>
                <td>جمع سبد خرید</td>
                <td>{totalPrice.toLocaleString()} تومان</td>
              </tr>
              <tr>
                <td>تخفیف</td>
                <td>{discount.toLocaleString()} تومان</td>
              </tr>
              <tr>
                <td>هزینه ارسال</td>
                <td>
                  {selectedSendMethod === "پست پیشتاز"
                    ? "رایگان"
                    : "150,000 تومان"}
                </td>
              </tr>
              <tr>
                <td>مبلغ کل</td>
                <td>{totalOverallPrice.toLocaleString()} تومان</td>
              </tr>
            </tbody>
          </table>
          <button className="pay-btn-Checkout" onClick={makePayment}>
            پرداخت
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
}
