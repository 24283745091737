import React, { useState, useEffect, useRef } from "react";
import ImageModalProduct from "./ImageModalProduct";
import "./ImagesProduct.css";

export default function ImagesProduct({ images }) {
  // const images = [
  //   "/assets/images/test18.jpg",
  //   "/assets/images/test19.jpg",
  //   "/assets/images/test20.jpg",
  //   "/assets/images/test21.jpg",
  //   "/assets/images/test22.jpg",
  // ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imageSize, setImageSize] = useState(0);
  const containerRef = useRef(null);

  useEffect(() => {
    const updateImageSize = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        setImageSize((containerWidth - 50) / 4);
      }
    };

    updateImageSize();
    window.addEventListener("resize", updateImageSize);
    return () => window.removeEventListener("resize", updateImageSize);
  }, []);

  const openModal = (index) => {
    setCurrentIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => setIsModalOpen(false);
  const nextImage = () => setCurrentIndex((currentIndex + 1) % images.length);
  const prevImage = () =>
    setCurrentIndex((currentIndex - 1 + images.length) % images.length);

  // Limit to 3 sub-images
  const subImages = images.slice(0, 4);
  const remainingImages = images.length - subImages.length;

  return (
    <div className="boxes-main-ImagesProduct" ref={containerRef}>
      {/* Main Image */}
      <img
        className="main-image-ImagesProduct"
        src={`${process.env.REACT_APP_SERVER_URL}/api${images[0].url}`}
        onClick={() => openModal(0)}
        alt={images[0].altText}
      />

      {/* Sub Images */}
      <div className="more-images-Boxes-ImagesProduct">
        {subImages.slice(1).map((img, index) => (
          <img
            key={index}
            className="more-images-ImagesProduct"
            src={`${process.env.REACT_APP_SERVER_URL}/api${img.url}`}
            onClick={() => openModal(index + 1)}
            alt={img.altText}
            style={{ width: imageSize, height: imageSize }}
          />
        ))}

        {/* +X if there are more images */}
        {remainingImages > 0 && (
          <div
            className="there-is-more-images-ImagesProduct more-images-ImagesProduct"
            onClick={() => openModal(2)}
            style={{ width: imageSize, height: imageSize }}
          >
            <p>+{remainingImages}</p>
          </div>
        )}
      </div>

      {/* Modal for Gallery */}
      {isModalOpen && (
        <ImageModalProduct
          images={images}
          currentIndex={currentIndex}
          onClose={closeModal}
          onNext={nextImage}
          onPrev={prevImage}
        />
      )}
    </div>
  );
}
