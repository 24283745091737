import React, { useState, useRef } from "react";
import "./ProductSlider.css";
import ProductBoxSlider from "./ProductBoxSlider";

export default function ProductSlider({ categoryTitle, products }) {
  const containerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const isDraggingRef = useRef(false);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    isDraggingRef.current = false; // Reset dragging state
    setStartX(e.pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    isDraggingRef.current = true; // Set dragging state
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 1; // scroll-fast
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  return (
    <div className="ProductSlider">
      <div className="main-box-ProductSlider">
        <h4 className="title-ProductSlider">{categoryTitle}</h4>
        <div className="products-slider-ProductSlider">
          <div
            className="products-container"
            ref={containerRef}
            onMouseDown={handleMouseDown}
            onMouseLeave={handleMouseLeave}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
          >
            {products.map((product, index) => (
              <ProductBoxSlider
                key={index}
                slug={product.slug}
                imageSource={product.mainImage}
                productName={product.name}
                productMainCategory={product.category}
                productPrice={product.price}
                offerLabel={product.salePrice ? "On Sale" : ""}
                productNewPrice={product.salePrice}
                isDragging={isDraggingRef.current} // Pass dragging state
                isAwalaible={product.quantity !== 0}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
