import React, { useState, useEffect } from "react";
import {
  adminCreateCategory,
  adminListCategory,
  adminDeleteCategory,
  adminUpdateCategory,
} from "../../scripts/admin/adminCategory";
import "./AdminCategories.css"; // Assuming you will create a CSS file for styling
import useStore from "../../stores/store";
import { toast } from "react-toastify";

const demoCategories = [];

export default function AdminCategories() {
  const [categories, setCategories] = useState(demoCategories);
  const [showModal, setShowModal] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState("");
  const [deleteCategoryId, setDeleteCategoryId] = useState(null);
  const [editCategoryId, setEditCategoryId] = useState(null);
  const [editCategoryName, setEditCategoryName] = useState("");
  const userAccessToken = useStore((state) => state.userAccessToken);

  useEffect(() => {
    const fetchCategories = async () => {
      const token = userAccessToken;
      const response = await adminListCategory(token);
      if (response.success) {
        const fetchedCategories = response.data.map((category) => ({
          id: category._id,
          name: category.name,
        }));
        setCategories(fetchedCategories);
      } else {
        toast.error("خطا در دریافت دسته‌بندی‌ها");
      }
    };

    fetchCategories();
  }, [userAccessToken]);

  const handleDelete = async (id) => {
    const token = userAccessToken;
    const response = await adminDeleteCategory(token, id);
    if (response.success) {
      setCategories(categories.filter((category) => category.id !== id));
      setDeleteCategoryId(null);
    } else {
      toast.error("خطا در حذف دسته‌بندی");
    }
  };

  const handleEdit = (id, name) => {
    setEditCategoryId(id);
    setEditCategoryName(name);
  };

  const handleUpdateCategory = async () => {
    const updatedCategoryData = {
      name: editCategoryName,
    };
    const token = userAccessToken;
    const response = await adminUpdateCategory(
      token,
      editCategoryId,
      updatedCategoryData
    );
    if (response.success) {
      setCategories(
        categories.map((category) =>
          category.id === editCategoryId
            ? { ...category, name: editCategoryName }
            : category
        )
      );
      setEditCategoryId(null);
      setEditCategoryName("");
    } else {
      toast.error("خطا در ویرایش دسته‌بندی");
    }
  };

  const handleAddCategory = async () => {
    const newCategoryData = {
      name: newCategoryName,
      description: "",
    };
    const response = await adminCreateCategory(
      userAccessToken,
      newCategoryData
    );
    if (response.success) {
      const newCategory = {
        id: response.data._id,
        name: response.data.name,
      };
      setCategories([...categories, newCategory]);
      setShowModal(false);
      setNewCategoryName("");
    } else {
      toast.error("خطا در ایجاد دسته‌بندی");
    }
  };

  return (
    <div className="admin-categories-AdminPage">
      <div className="header-AdminPage">
        <h2>دسته‌بندی‌ها</h2>
        <button
          className="new-category-btn-AdminPage"
          onClick={() => setShowModal(true)}
        >
          دسته‌بندی جدید
        </button>
      </div>
      <table className="categories-table-AdminPage">
        <thead>
          <tr>
            <th>نام</th>
            <th>آیدی</th>
            <th>عملیات</th>
          </tr>
        </thead>
        <tbody>
          {categories.map((category) => (
            <tr key={category.id}>
              <td>{category.name}</td>
              <td>{category.id}</td>
              <td>
                <button
                  className="edit-btn-AdminPage"
                  onClick={() => handleEdit(category.id, category.name)}
                >
                  ویرایش
                </button>
                <button
                  className="delete-btn-AdminPage"
                  onClick={() => setDeleteCategoryId(category.id)}
                >
                  حذف
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {showModal && (
        <div className="modal-AdminPage">
          <div className="modal-content-AdminPage">
            <h3>افزودن دسته‌بندی جدید</h3>
            <label style={{ direction: "rtl" }}>
              نام دسته‌بندی:
              <input
                style={{ fontFamily: "Vazir" }}
                type="text"
                value={newCategoryName}
                onChange={(e) => setNewCategoryName(e.target.value)}
              />
            </label>
            <button style={{ fontFamily: "Vazir" }} onClick={handleAddCategory}>
              ذخیره
            </button>
            <button
              style={{ fontFamily: "Vazir" }}
              onClick={() => setShowModal(false)}
            >
              لغو
            </button>
          </div>
        </div>
      )}
      {deleteCategoryId && (
        <div className="modal-AdminPage">
          <div className="modal-content-AdminPage">
            <h3>آیا مطمئن هستید که می‌خواهید این دسته‌بندی را حذف کنید؟</h3>
            <button
              style={{ fontFamily: "Vazir" }}
              onClick={() => handleDelete(deleteCategoryId)}
            >
              بله
            </button>
            <button
              style={{ fontFamily: "Vazir" }}
              onClick={() => setDeleteCategoryId(null)}
            >
              خیر
            </button>
          </div>
        </div>
      )}
      {editCategoryId && (
        <div className="modal-AdminPage">
          <div className="modal-content-AdminPage">
            <h3>ویرایش دسته‌بندی</h3>
            <label style={{ direction: "rtl" }}>
              نام دسته‌بندی:
              <input
                style={{ fontFamily: "Vazir" }}
                type="text"
                value={editCategoryName}
                onChange={(e) => setEditCategoryName(e.target.value)}
              />
            </label>
            <button
              style={{ fontFamily: "Vazir" }}
              onClick={handleUpdateCategory}
            >
              ذخیره
            </button>
            <button
              style={{ fontFamily: "Vazir" }}
              onClick={() => setEditCategoryId(null)}
            >
              لغو
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
