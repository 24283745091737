import React from "react";
import "./CategoryRow.css";
import CategoryBoxImage from "./CategoryBoxImage";

export default function CategoryRow({ data }) {
  return (
    <div className="CategoryRow">
      <div className="box-CategoryRow">
        {data.map((item, index) => (
          <div key={index} className={`main-col-CategoryRow col-${item.cols}`}>
            {item.imageSrc.map((src, idx) => (
              <CategoryBoxImage
                key={idx}
                imageSrc={src}
                link={item.links[idx]}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
