import React from "react";
import "./Pagination.css";

function Pagination({ currentPage, totalPages, onPageChange }) {
  const getVisiblePages = () => {
    if (totalPages <= 3) {
      return [...Array(totalPages).keys()].map((x) => x + 1);
    } else if (currentPage <= 2) {
      return [1, 2, 3];
    } else if (currentPage >= totalPages - 1) {
      return [totalPages - 2, totalPages - 1, totalPages];
    } else {
      return [currentPage - 1, currentPage, currentPage + 1];
    }
  };

  const visiblePages = getVisiblePages();

  return (
    <div className="Pagination">
      <button onClick={() => onPageChange(1)} disabled={currentPage === 1}>
        <img
          className="images-icons-Pagination"
          src="/assets/icons/right-double-arrow-icon.png"
          alt="Change page right double arrow"
        />
      </button>
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        <img
          className="images-icons-Pagination"
          src="/assets/icons/right-arrow.png"
          alt="Change page right arrow"
        />
      </button>
      {visiblePages.map((number) => (
        <button
          key={number}
          onClick={() => onPageChange(number)}
          className={currentPage === number ? "active-Pagination" : ""}
        >
          {number}
        </button>
      ))}
      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        <img
          className="images-icons-Pagination"
          src="/assets/icons/left-arrow.png"
          alt="Change page left arrow"
        />
      </button>
      <button
        onClick={() => onPageChange(totalPages)}
        disabled={currentPage === totalPages}
      >
        <img
          className="images-icons-Pagination"
          src="/assets/icons/left-double-arrow-icon.png"
          alt="Change page left double arrow"
        />
      </button>
    </div>
  );
}

export default Pagination;
