import { createBrowserRouter, Navigate } from "react-router-dom";
import Home from "../pages/Home";
import NotFound from "../pages/NotFound";
import Products from "../pages/Products";
import Product from "../pages/Product";
import Cart from "../pages/Cart";
import Dashboard from "../pages/Dashboard";
import Login from "../pages/Login";
import Checkout from "../pages/Checkout";
import useStore from "../stores/store";
import Orders from "../pages/Orders";
import AboutUs from "../pages/AboutUs";
import AdminPage from "../pages/admin/AdminPage";
import AdminHome from "../pages/admin/AdminHome";
import AdminProducts from "../pages/admin/AdminProducts";
import AdminOrders from "../pages/admin/AdminOrders";
import AdminImages from "../pages/admin/AdminImages";
import AdminCategories from "../pages/admin/AdminCategories";
import ProductForm from "../pages/admin/ProductForm"; // Import the new ProductForm component

const RequireAuth = ({ children }) => {
  const needLogin = useStore((state) => state.needLogin);
  return needLogin === false ? children : <Navigate to="/login" />;
};

const LoginRedirect = ({ children }) => {
  const needLogin = useStore((state) => state.needLogin);
  return needLogin === true ? children : <Navigate to="/dashboard" />;
};

const RequireAdminAuth = ({ children }) => {
  const isAdmin = useStore((state) => state.isAdmin);
  return isAdmin === true ? children : <Navigate to="/login" />;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <NotFound />,
  },
  {
    path: "/products",
    element: <Products />,
  },
  {
    path: "/products/:productId",
    element: <Product />,
  },
  {
    path: "/cart",
    element: <Cart />,
  },
  {
    path: "/dashboard",
    element: (
      <RequireAuth>
        <Dashboard />
      </RequireAuth>
    ),
  },
  {
    path: "/dashboard/orders",
    element: (
      <RequireAuth>
        <Orders />
      </RequireAuth>
    ),
  },
  {
    path: "/login",
    element: (
      <LoginRedirect>
        <Login />
      </LoginRedirect>
    ),
  },
  {
    path: "/checkout",
    element: (
      <RequireAuth>
        <Checkout />
      </RequireAuth>
    ),
  },
  {
    path: "/about-us",
    element: <AboutUs />,
  },
  {
    path: "/admin",
    element: (
      <RequireAdminAuth>
        <AdminPage />
      </RequireAdminAuth>
    ),
    children: [
      { path: "home", element: <AdminHome /> },
      { path: "products", element: <AdminProducts /> },
      { path: "orders", element: <AdminOrders /> },
      { path: "images", element: <AdminImages /> },
      { path: "categories", element: <AdminCategories /> },
      { path: "products/new", element: <ProductForm /> }, // New route for creating a product
      { path: "products/edit/:productId", element: <ProductForm /> }, // New route for editing a product
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

export default router;
