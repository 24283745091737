import React from "react";
import "./NavbarMenu.css";
import { Link, useNavigate } from "react-router-dom";
import menus from "../../stores/menus";

export default function NavbarMenu({ isSticky }) {
  const navigate = useNavigate();

  return (
    <nav className="NavbarMenu">
      <ul className="menu-list-NavbarMenu">
        {menus.map((menu) => (
          <>
            <li className="menu-item-NavbarMenu">
              {menu.title}
              <div className="submenu-NavbarMenu" style={{ height: "700px" }}>
                <div className="submenu-insider-NavbarMenu">
                  <div className="shadow-submenu-insider-NavbarMenu"></div>
                  {menu.image ? (
                    <div className="left-box-submenu-insider-NavbarMenu">
                      <img
                        src={menu.image}
                        width={"100%"}
                        height={"auto"}
                        alt="submenu"
                      />
                    </div>
                  ) : (
                    <div style={{ height: "395px", width: "1px" }}></div>
                  )}

                  <div className="right-box-submenu-insider-NavbarMenu">
                    {menu.submenus.map((submenu) => (
                      <Link
                        to={`/products?category=` + submenu.url}
                        className="item-box-submenu-NavbarMenu"
                      >
                        <p
                          style={
                            submenu.isMain
                              ? { color: "red" }
                              : { color: "#444" }
                          }
                        >
                          {submenu.title}
                        </p>
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </li>
          </>
        ))}
        <div
          className="back-screen-NavbarMenu"
          style={
            isSticky
              ? { top: "60px", height: "calc(100vh - 50px)" }
              : { top: "110px", height: "calc(100vh - 100px)" }
          }
        ></div>
        <Link
          to="/about-us"
          className="menu-item-NavbarMenu"
          style={{ textDecoration: "none" }}
        >
          درباره ما
        </Link>
      </ul>
    </nav>
  );
}
