import axios from "axios";

export const removeAddress = async (userAccessToken, addressId) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_SERVER_URL}/api/addresses/remove/${addressId}`,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
