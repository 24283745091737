import React, { useEffect } from "react";
import "./ImageModalProduct.css";

export default function ImageModalProduct({
  images,
  currentIndex,
  onClose,
  onNext,
  onPrev,
}) {
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") onClose();
      if (e.key === "ArrowRight") onNext();
      if (e.key === "ArrowLeft") onPrev();
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [onClose, onNext, onPrev]);

  return (
    <div className="modal-overlay-ImageModalProduct" onClick={onClose}>
      <div
        className="modal-content-ImageModalProduct"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          className="modal-close-ImageModalProduct icons-ImageModalProduct"
          onClick={onClose}
        >
          <img
            className="icons-image-ImageModalProduct"
            src="/assets/icons/close-black.png"
            alt="Close modal"
          />
        </button>
        <button
          className="modal-prev-ImageModalProduct icons-ImageModalProduct"
          onClick={onPrev}
        >
          <img
            className="icons-image-ImageModalProduct"
            src="/assets/icons/left-arrow-black.png"
            alt="Previous one"
          />
        </button>
        <img
          src={`${process.env.REACT_APP_SERVER_URL}/api${images[currentIndex].url}`}
          alt={images[currentIndex].altText}
          className="modal-image-ImageModalProduct"
        />
        <button
          className="modal-next-ImageModalProduct icons-ImageModalProduct"
          onClick={onNext}
        >
          <img
            className="icons-image-ImageModalProduct"
            src="/assets/icons/right-arrow-black.png"
            alt="Next one"
          />
        </button>
      </div>
    </div>
  );
}
