let menus = [
  {
    title: "کنسول های بازی",
    submenus: [
      {
        title: "سونی",
        isMain: true,
        url: "کنسول-بازی-سونی",
      },
      {
        title: "PlayStation 5",
        isMain: false,
        url: "کنسول-بازی-PlayStation-5",
      },
      {
        title: "PlayStation 4",
        isMain: false,
        url: "کنسول-بازی-PlayStation-4",
      },
      {
        title: "مایکروسافت",
        isMain: true,
        url: "کنسول-بازی-مایکروسافت",
      },
      {
        title: "XBOX",
        isMain: false,
        url: "کنسول-بازی-XBOX",
      },
      {
        title: "نینتندو",
        isMain: true,
        url: "کنسول-بازی-نینتندو",
      },
      {
        title: "Nintendo Switch",
        isMain: false,
        url: "کنسول-بازی-Nintendo-Switch",
      },
      {
        title: "Nintendo Switch Lite",
        isMain: false,
        url: "کنسول-بازی-Nintendo-Switch-Lite",
      },
      {
        title: "کنسول های دیگر",
        isMain: true,
        url: "کنسول-های-دیگر",
      },
    ],
    image: "/assets/images/کنسول-های-بازی.png",
  },
  {
    title: "لوازم جانبی",
    submenus: [
      {
        title: "PlayStation 5",
        isMain: true,
        url: "PlayStation-5",
      },
      {
        title: "کنترلر و DualSense برای PS5",
        isMain: false,
        url: "کنترلر-و-DualSense-برای-PS5",
      },
      {
        title: "هدست PS5",
        isMain: false,
        url: "هدست-PS5",
      },
      {
        title: "پایه و شارژر PS5",
        isMain: false,
        url: "پایه-و-شارژر-PS5",
      },
      {
        title: "هارد و حافظه جانبی PS5",
        isMain: false,
        url: "هارد-و-حافظه-جانبی-PS5",
      },
      {
        title: "کاور و فیس پلیت PS5",
        isMain: false,
        url: "کاور-و-فیس-پلیت-PS5",
      },
      {
        title: "فرمان رانندگی PS5",
        isMain: false,
        url: "فرمان-رانندگی-PS5",
      },
      {
        title: "کیف مخصوص PS5",
        isMain: false,
        url: "کیف-مخصوص-PS5",
      },
      {
        title: "کاور و کیف DualSense برای PS5",
        isMain: false,
        url: "کاور-و-کیف-DualSense-برای-PS5",
      },
      {
        title: "دیگر لوازم جانبی PS5",
        isMain: false,
        url: "دیگر-لوازم-جانبی-PS5",
      },
      {
        title: "Xbox",
        isMain: true,
        url: "Xbox",
      },
      {
        title: "کنترلر های ایکس باکس",
        isMain: false,
        url: "کنترلر-های-ایکس-باکس",
      },
      {
        title: "هدست مناسب ایکس باکس",
        isMain: false,
        url: "هدست-مناسب-ایکس-باکس",
      },
      {
        title: "هارد و حافظه جانبی ایکس باکس",
        isMain: false,
        url: "هارد-و-حافظه-جانبی-ایکس-باکس",
      },
      {
        title: "پایه و شارژر ایکس باکس",
        isMain: false,
        url: "پایه-و-شارژر-ایکس-باکس",
      },
      {
        title: "فرمان رانندگی ایکس باکس",
        isMain: false,
        url: "فرمان-رانندگی-ایکس-باکس",
      },
      {
        title: "کیف و کاور ایکس باکس",
        isMain: false,
        url: "کیف-و-کاور-ایکس-باکس",
      },
      {
        title: "دیگر لوازم جانبی ایکس باکس",
        isMain: false,
        url: "دیگر-لوازم-جانبی-ایکس-باکس",
      },
      {
        title: "PlayStation 4",
        isMain: true,
        url: "PlayStation-4",
      },
      {
        title: "کنترلر و DualShock برای PS4",
        isMain: false,
        url: "کنترلر-و-DualShock-برای-PS4",
      },
      {
        title: "هدست مناسب PS4",
        isMain: false,
        url: "هدست-مناسب-PS4",
      },
      {
        title: "فرمان رانندگی PS4",
        isMain: false,
        url: "فرمان-رانندگی-PS4",
      },
      {
        title: "پایه و شارژر PS4",
        isMain: false,
        url: "پایه-و-شارژر-PS4",
      },
      {
        title: "هارد و حافظه جانبی PS4",
        isMain: false,
        url: "هارد-و-حافظه-جانبی-PS4",
      },
      {
        title: "کاور و کیف کنترلر PS4",
        isMain: false,
        url: "کاور-و-کیف-کنترلر-PS4",
      },
      {
        title: "کیف مخصوص PS4",
        isMain: false,
        url: "کیف-مخصوص-PS4",
      },
      {
        title: "دیگر لوازم جانبی PS4",
        isMain: false,
        url: "دیگر-لوازم-جانبی-PS4",
      },
      {
        title: "Nintendo Switch",
        isMain: true,
        url: "Nintendo-Switch",
      },
      {
        title: "کنترلر جوی کان نینتندو سوییچ",
        isMain: false,
        url: "کنترلر-جوی-کان-نینتندو-سوییچ",
      },
      {
        title: "هدست مناسب نینتندو سوییچ",
        isMain: false,
        url: "هدست-مناسب-نینتندو-سوییچ",
      },
      {
        title: "فرمان رانندگی نینتندو سوییچ",
        isMain: false,
        url: "فرمان-رانندگی-نینتندو-سوییچ",
      },
      {
        title: "پایه و شارژر نینتندو سوییچ",
        isMain: false,
        url: "پایه-و-شارژر-نینتندو-سوییچ",
      },
      {
        title: "هارد و حافظه جانبی نینتندو سوییچ",
        isMain: false,
        url: "هارد-و-حافظه-جانبی-نینتندو-سوییچ",
      },
      {
        title: "محافظ و کیف کنترلر نینتندو سوییچ",
        isMain: false,
        url: "محافظ-و-کیف-کنترلر-نینتندو-سوییچ",
      },
      {
        title: "کیف مخصوص نینتندو سوییچ",
        isMain: false,
        url: "کیف-مخصوص-نینتندو-سوییچ",
      },
      {
        title: "دیگر لوازم جانبی نینتندو سوییچ",
        isMain: false,
        url: "دیگر-لوازم-جانبی-نینتندو-سوییچ",
      },
    ],
    image: "",
  },
  {
    title: "عناوین بازی",
    submenus: [
      {
        title: "PlayStation 5",
        isMain: true,
        url: "PlayStation-5",
      },
      {
        title: "PlayStation 4",
        isMain: true,
        url: "PlayStation-4",
      },
      {
        title: "PlayStation VR",
        isMain: true,
        url: "PlayStation-VR",
      },
      {
        title: "Xbox",
        isMain: true,
        url: "Xbox",
      },
      {
        title: "Nintendo Switch",
        isMain: true,
        url: "Nintendo-Switch",
      },
      {
        title: "بازی های کار کرده",
        isMain: true,
        url: "بازی-های-کار-کرده",
      },
      {
        title: "بازی کار کرده PS4",
        isMain: false,
        url: "بازی-کار-کرده-PS4",
      },
      {
        title: "بازی کار کرده Xbox",
        isMain: false,
        url: "بازی-کار-کرده-Xbox",
      },
      {
        title: "بازی کار کرده نینتندو سوییچ",
        isMain: false,
        url: "بازی-کار-کرده-نینتندو-سوییچ",
      },
      {
        title: "بازی کار کرده PS5",
        isMain: false,
        url: "بازی-کار-کرده-PS5",
      },
    ],
    image: "",
  },
  {
    title: "تجهیزات گیمینگ",
    submenus: [
      {
        title: "مانیتور گیمینگ",
        isMain: true,
        url: "مانیتور-گیمینگ",
      },
      {
        title: "مانیتور مناسب کنسول",
        isMain: false,
        url: "مانیتور-مناسب-کنسول",
      },
      {
        title: "مانیتور مخصوص کامپیوتر",
        isMain: false,
        url: "مانیتور-مخصوص-کامپیوتر",
      },
      {
        title: "واقعیت مجازی و VR",
        isMain: true,
        url: "واقعیت-مجازی-و-VR",
      },
      {
        title: "هدست و هدفون گیمینگ",
        isMain: true,
        url: "هدست-و-هدفون-گیمینگ",
      },
      {
        title: "صندلی گیمینگ",
        isMain: true,
        url: "صندلی-گیمینگ",
      },
      {
        title: "مودم و روتر گیمینگ",
        isMain: true,
        url: "مودم-و-روتر-گیمینگ",
      },
      {
        title: "رانندگی و پرواز",
        isMain: true,
        url: "رانندگی-و-پرواز",
      },
      {
        title: "تجهزیات استریم",
        isMain: true,
        url: "تجهزیات-استریم",
      },
      {
        title: "میکروفون",
        isMain: false,
        url: "میکروفون",
      },
      {
        title: "دوربین و وبکم",
        isMain: false,
        url: "دوربین-و-وبکم",
      },
      {
        title: "تجهزیات نورپردازی",
        isMain: true,
        url: "تجهزیات-نورپردازی",
      },
      {
        title: "موس گیمینگ",
        isMain: true,
        url: "موس-گیمینگ",
      },
      {
        title: "کیبورد گیمینگ",
        isMain: true,
        url: "کیبورد-گیمینگ",
      },
      {
        title: "گیم پد",
        isMain: true,
        url: "گیم-پد",
      },
      {
        title: "موس پد",
        isMain: true,
        url: "موس-پد",
      },
      {
        title: "موس و کیبورد کامپیوتر و لپ تاپ",
        isMain: true,
        url: "موس-و-کیبورد-کامپیوتر-و-لپ-تاپ",
      },
      {
        title: "اسپیکر گیمینگ",
        isMain: true,
        url: "اسپیکر-گیمینگ",
      },
      {
        title: "فن و خنک کننده",
        isMain: true,
        url: "فن-و-خنک-کننده",
      },
      {
        title: "هارد و حافظه SSD",
        isMain: true,
        url: "هارد-و-حافظه-SSD",
      },
    ],
    image: "",
  },
  {
    title: "دیگر کالاها",
    submenus: [
      {
        title: "اکشن فیگور",
        isMain: true,
        url: "اکشن-فیگور",
      },
      {
        title: "فانکو پاپ Funko Pop",
        isMain: true,
        url: "فانکو-پاپ-Funko-Pop",
      },
      {
        title: "دیووم Divoom",
        isMain: true,
        url: "دیووم-Divoom",
      },
      {
        title: "کاور و برچسب",
        isMain: true,
        url: "کاور-و-برچسب",
      },
      {
        title: "کلاه",
        isMain: true,
        url: "کلاه",
      },
      {
        title: "کیف پول",
        isMain: true,
        url: "کیف-پول",
      },
    ],
    image: "/assets/images/action-figures.png",
  },
];

export default menus;
