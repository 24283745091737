import axios from "axios";

export const adminListProducts = async (token, page = 1) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/admin/products?page=${page}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const adminDeleteProduct = async (token, productId) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_SERVER_URL}/api/admin/products/${productId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const adminGetProductDetails = async (token, productId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/admin/products/${productId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const adminUpdateProduct = async (token, productId, productData) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_SERVER_URL}/api/admin/products/${productId}`,
      productData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const adminCreateProduct = async (token, productData) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/admin/products`,
      productData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
