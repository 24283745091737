import React from "react";
import "./OrdersDashboard.css";
import formatPrice from "../../scripts/formatPrice";
import { useNavigate } from "react-router-dom";

const statusToPersian = {
  awaiting_payment: "در انتظار پرداخت",
  awaiting_confirmation: "در انتظار تایید",
  processing: "در حال آماده سازی",
  shipped: "ارسال شده",
  delivered: "تحویل داده شده",
  cancelled: "لغو شده",
};

export default function OrdersDashboard({ ordersList }) {
  const navigate = useNavigate();
  return (
    <div className="container-OrdersDashboard">
      <div className="responsive-table-OrdersDashboard">
        <table className="table-OrdersDashboard">
          <thead>
            <tr className="row-OrdersDashboard">
              <th className="header-OrdersDashboard">شناسه سفارش</th>
              <th className="header-OrdersDashboard">تاریخ ثبت سفارش</th>
              <th className="header-OrdersDashboard">جمع</th>
              <th className="header-OrdersDashboard">وضعیت سفارش</th>
              <th className="header-OrdersDashboard">جزئیات</th>
            </tr>
          </thead>
          <tbody>
            {ordersList.map((order) => (
              <tr key={order._id} className="row-OrdersDashboard">
                <td className="item-OrdersDashboard">{order.orderId}</td>
                <td
                  className="item-OrdersDashboard"
                  style={{ direction: "ltr" }}
                >
                  {new Date(order.createdAt).toLocaleString("fa-IR")}
                </td>
                <td className="item-OrdersDashboard">
                  {formatPrice(order.totalFinalPrice)} تومان
                </td>
                <td className="item-OrdersDashboard item-status-OrdersDashboard">
                  {statusToPersian[order.status] || order.status}
                </td>
                <td className="item-OrdersDashboard">
                  <button
                    onClick={() =>
                      navigate(`/dashboard/orders?orderId=${order._id}`)
                    }
                    className="see-details-btn-OrdersDashboard"
                  >
                    مشاهده جزئیات
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
