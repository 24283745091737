import axios from "axios";

export const setProductsToCart = async (products, userAccessToken) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/cart/set`,
      {
        products: products.map((product) => {
          const productData = {
            productId: product.id,
            quantity: product.quantity,
          };
          if (product.variantId !== null) {
            productData.variantId = product.variantId;
          }
          return productData;
        }),
      },
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
