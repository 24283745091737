import React, { useState, useEffect } from "react";
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import "./AdminPage.css"; // Assuming you will create a CSS file for styling

export default function AdminPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(location.pathname);

  useEffect(() => {
    if (location.pathname === "/admin") {
      navigate("/admin/home");
    }
  }, [location.pathname, navigate]);

  useEffect(() => {
    setActiveTab(location.pathname);
  }, [location.pathname]);

  return (
    <div className="admin-dashboard-AdminPage">
      <nav className="admin-menu-AdminPage">
        <ul>
          <li>
            <Link
              to="/admin/home"
              className={activeTab === "/admin/home" ? "active" : ""}
            >
              داشبورد
            </Link>
          </li>
          <li>
            <Link
              to="/admin/products"
              className={activeTab === "/admin/products" ? "active" : ""}
            >
              محصولات
            </Link>
          </li>
          <li>
            <Link
              to="/admin/orders"
              className={activeTab === "/admin/orders" ? "active" : ""}
            >
              سفارش ها
            </Link>
          </li>
          <li>
            <Link
              to="/admin/images"
              className={activeTab === "/admin/images" ? "active" : ""}
            >
              عکس ها
            </Link>
          </li>
          <li>
            <Link
              to="/admin/categories"
              className={activeTab === "/admin/categories" ? "active" : ""}
            >
              دسته بندی ها
            </Link>
          </li>
          <li>
            <Link to="/">بازگشت به سایت</Link>
          </li>
        </ul>
        <div
          style={{
            position: "absolute",
            bottom: "0px",
            textAlign: "center",
          }}
        >
          <p>PS4Tehransar</p>
        </div>
      </nav>
      <div className="admin-content-AdminPage">
        <Outlet />
      </div>
    </div>
  );
}
