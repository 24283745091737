import React from "react";
import "./DashNavBar.css";

export default function DashNavBar({ activeTab, activeTabHandler }) {
  return (
    <div className="container-DashNavBar">
      <div className="container-div-DashNavBar">
        <div
          className={
            activeTab === "profile"
              ? "active-box-DashNavBar box-DashNavBar"
              : "box-DashNavBar"
          }
          onClick={() => {
            activeTabHandler("profile");
          }}
        >
          <p className="title-DashNavBar">پروفایل</p>
        </div>
        <div
          className={
            activeTab === "orders"
              ? "active-box-DashNavBar box-DashNavBar"
              : "box-DashNavBar"
          }
          onClick={() => {
            activeTabHandler("orders");
          }}
        >
          <p>سفارش ها</p>
        </div>
        <div
          className={
            activeTab === "addresses"
              ? "active-box-DashNavBar box-DashNavBar"
              : "box-DashNavBar"
          }
          onClick={() => {
            activeTabHandler("addresses");
          }}
        >
          <p>آدرس ها</p>
        </div>
        <div
          className={
            activeTab === "favourites"
              ? "active-box-DashNavBar box-DashNavBar"
              : "box-DashNavBar"
          }
          onClick={() => {
            activeTabHandler("favourites");
          }}
        >
          <p>علاقه مندی ها</p>
        </div>
      </div>
    </div>
  );
}
