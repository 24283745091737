import React, { useEffect, useState } from "react";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import MainSlider from "../components/home/MainSlider";
import ProductSlider from "../components/home/ProductSlider";
import CategoryRow from "../components/home/CategoryRow";
import {
  categoryData,
  categoryDataMobiles,
  categoryDataTablets,
} from "../stores/categoryData";
import BlogRow from "../components/home/BlogRow";
import {
  getProductsForMenuRandom,
  getNewestProducts,
  getRandomConsoleProducts,
  getRandomGameDiscs,
  getRandomAccessories,
} from "../scripts/getProductsForMenu";

export default function Home() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [randomProducts, setRandomProducts] = useState([]);
  const [newestProducts, setNewestProducts] = useState([]);
  const [consoleProducts, setConsoleProducts] = useState([]);
  const [gameDiscs, setGameDiscs] = useState([]);
  const [accessories, setAccessories] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      const random = await getProductsForMenuRandom();
      setRandomProducts(random);

      const newest = await getNewestProducts();
      setNewestProducts(newest);

      const consoles = await getRandomConsoleProducts();
      setConsoleProducts(consoles);

      const games = await getRandomGameDiscs();
      setGameDiscs(games);

      const accessories = await getRandomAccessories();
      setAccessories(accessories);
    };
    fetchProducts();
  }, []);

  return (
    <>
      <Navbar />
      <MainSlider />
      <ProductSlider
        categoryTitle="جدیدترین کالاها"
        products={newestProducts}
      />
      <ProductSlider
        categoryTitle="محصولات پیشنهادی"
        products={randomProducts}
      />
      {windowSize.width <= 479 ? (
        <>
          <CategoryRow data={categoryDataMobiles[0]} />
          <CategoryRow data={categoryDataMobiles[1]} />
          <CategoryRow data={categoryDataMobiles[2]} />
          <CategoryRow data={categoryDataMobiles[3]} />
          <CategoryRow data={categoryDataMobiles[4]} />
          <CategoryRow data={categoryDataMobiles[5]} />
        </>
      ) : windowSize.width > 1024 ? (
        <>
          <CategoryRow data={categoryData[0]} />
          <CategoryRow data={categoryData[1]} />
        </>
      ) : windowSize.width > 479 && windowSize.width <= 1024 ? (
        <>
          <CategoryRow data={categoryDataTablets[0]} />
          <CategoryRow data={categoryDataTablets[1]} />
          <CategoryRow data={categoryDataTablets[2]} />
        </>
      ) : null}

      <ProductSlider
        categoryTitle="کنسول های بازی"
        products={consoleProducts}
      />
      <ProductSlider categoryTitle="بازی ها" products={gameDiscs} />
      <ProductSlider categoryTitle="لوازم جانبی" products={accessories} />
      {windowSize.width <= 479 ? (
        <>
          <CategoryRow data={categoryDataMobiles[6]} />
          <CategoryRow data={categoryDataMobiles[7]} />
          <CategoryRow data={categoryDataMobiles[8]} />
          <CategoryRow data={categoryDataMobiles[9]} />
        </>
      ) : windowSize.width > 1024 ? (
        <>
          <CategoryRow data={categoryData[2]} />
        </>
      ) : windowSize.width > 479 && windowSize.width <= 1024 ? (
        <>
          <CategoryRow data={categoryDataTablets[3]} />
        </>
      ) : null}
      {/* <BlogRow /> */}
      <Footer />
    </>
  );
}
