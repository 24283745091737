import axios from "axios";

export const getProductsForMenuRandom = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/products/random`
    );
    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error("Failed to fetch random products");
    }
  } catch (error) {
    console.error("Error fetching random products:", error);
    return [];
  }
};

export const getNewestProducts = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/products/newest`
    );
    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error("Failed to fetch newest products");
    }
  } catch (error) {
    console.error("Error fetching newest products:", error);
    return [];
  }
};

export const getRandomConsoleProducts = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/products/random/کنسول-های-بازی`
    );
    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error("Failed to fetch random console products");
    }
  } catch (error) {
    console.error("Error fetching random console products:", error);
    return [];
  }
};

export const getRandomGameDiscs = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/products/random/دیسک-بازی`
    );
    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error("Failed to fetch random game discs");
    }
  } catch (error) {
    console.error("Error fetching random game discs:", error);
    return [];
  }
};

export const getRandomAccessories = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/products/random/لوازم-جانبی`
    );
    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error("Failed to fetch random accessories");
    }
  } catch (error) {
    console.error("Error fetching random accessories:", error);
    return [];
  }
};
