import axios from "axios";

export const adminListOrders = async (token, page = 1) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/admin/orders/list?page=${page}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const adminDeleteOrder = async (token, orderId) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_SERVER_URL}/api/admin/orders/delete/${orderId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const adminUpdateOrderStatus = async (token, orderId, status) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_SERVER_URL}/api/admin/orders/update/${orderId}`,
      { status },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
