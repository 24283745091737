import React from "react";
import "./ShopImageFooter.css";

export default function ShopImageFooter() {
  return (
    <div className="ShopImageFooter">
      <div className="shop-address-Box-ShopImageFooter">
        <img
          className="shop-address-Box-background-image-ShopImageFooter"
          src="/assets/images/shop-image-address.webp"
          alt="Shop address"
        />
        <div className="leftBox-shop-address-Box-ShopImageFooter">
          <p className="leftText-shop-address-ShopImageFooter">
            ما را در شبکه های اجتماعی دنبال کنید:
          </p>
          <div className="leftBox-social-icons-ShopImageFooter">
            <a
              href="https://www.instagram.com/ps4_tehransar"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="social-icons-image-ShopImageFooter"
                src="/assets/icons/instagram_socials.png"
                alt="Instagram"
              />
            </a>
            <a
              href="https://t.me/ps4tehransar"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="social-icons-image-ShopImageFooter"
                src="/assets/icons/telegram_socials.png"
                alt="Telegram"
              />
            </a>
          </div>
        </div>
        <div className="middleBox-shop-address-Box-ShopImageFooter">
          <div className="middleBox-divider-ShopImageFooter"></div>
        </div>
        <div className="rightBox-shop-address-Box-ShopImageFooter">
          <div className="rightBox-Box-ShopImageFooter">
            <p className="rightText-shop-address-ShopImageFooter">
              تهران ، تهرانسر ، جنب میدان کماللملک ، پاساژ الهام ، طبقه اول ،
              پلاک 1{" "}
            </p>
            <div className="location-image-icon-ShopImageFooter">
              <img
                className="location-image-ShopImageFooter"
                src="/assets/icons/location.png"
                alt="Location"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
