import axios from "axios";

export const uploadImage = async (token, imageData) => {
  try {
    const formData = new FormData();
    formData.append("image", imageData.file);
    formData.append("name", imageData.name);
    formData.append("altText", imageData.altText);

    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/admin/upload/image`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const fetchImages = async (token, page = 1) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/admin/upload/images?page=${page}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const updateImageDetails = async (token, imageId, imageData) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_SERVER_URL}/api/admin/upload/image/${imageId}`,
      imageData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteImage = async (token, imageId) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_SERVER_URL}/api/admin/upload/image/${imageId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
