import React, { useEffect, useState } from "react";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import DashNavBar from "../components/dashboard/DashNavBar";
import "./Dashboard.css";
import ProfileDashboard from "../components/dashboard/ProfileDashboard";
import OrdersDashboard from "../components/dashboard/OrdersDashboard";
import AddressesDashBoard from "../components/dashboard/AddressesDashBoard";
import FavouritesDashboard from "../components/dashboard/FavouritesDashboard";
import useStore from "../stores/store";
import { useNavigate, useLocation } from "react-router-dom";
import { getUserDetails } from "../scripts/getUserDetails";
import { getOrdersList } from "../scripts/getOrdersList";
import { getAddressesList } from "../scripts/getAddressesList";
import { updateUserDetails } from "../scripts/updateUserDetails";
import { updateAddress } from "../scripts/updateAddressesList";
import { removeAddress } from "../scripts/removeAddress";
import { createAddress } from "../scripts/createAddress";
import { toast } from "react-toastify";

export default function Dashboard() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const validTabs = ["profile", "orders", "addresses", "favourites"];
  let initialTab = queryParams.get("tab") || "profile";

  useEffect(() => {
    if (!queryParams.has("tab") || !validTabs.includes(initialTab)) {
      queryParams.set("tab", "profile");
      navigate(`${location.pathname}?${queryParams.toString()}`, {
        replace: true,
      });
      initialTab = "profile";
    }
  }, [location, navigate, queryParams, initialTab]);

  let [activeTab, setActiveTab] = useState(initialTab);
  const needLogin = useStore((state) => state.needLogin);
  const setNeedLogin = useStore((state) => state.setNeedLogin);
  const userAccessToken = useStore((state) => state.userAccessToken);
  const setUserAccessToken = useStore((state) => state.setUserAccessToken);
  const [userDetails, setUserDetails] = useState({});
  const [ordersList, setOrdersList] = useState([]);
  const [addressesList, setAddressesList] = useState([]);

  let activeTabHandler = (tab) => {
    setActiveTab(tab);
    queryParams.set("tab", tab);
    navigate(`${location.pathname}?${queryParams.toString()}`, {
      replace: true,
    });
  };

  const apiHandle = async () => {
    try {
      const response = await getUserDetails(userAccessToken);
      if (response.status === 200) {
        setUserDetails(response.data);
      } else if (
        response.status === 400 &&
        response.message === "Invalid token."
      ) {
        setNeedLogin(true);
        setUserAccessToken(null);
        navigate("/login");
      } else if (
        response.status === 404 &&
        response.message === "User not found."
      ) {
        setNeedLogin(true);
        setUserAccessToken(null);
        navigate("/login");
      }
    } catch (error) {
      console.error("Failed to fetch user data:", error);
    }
    try {
      const response = await getOrdersList(userAccessToken);
      if (response.status === 200) {
        setOrdersList(response.data);
      } else if (
        response.status === 400 &&
        response.message === "Invalid token."
      ) {
        setNeedLogin(true);
        setUserAccessToken(null);
        navigate("/login");
      } else if (
        response.status === 404 &&
        response.message === "User not found."
      ) {
        setNeedLogin(true);
        setUserAccessToken(null);
        navigate("/login");
      }
    } catch (error) {
      console.error("Failed to fetch user data:", error);
    }
    try {
      const response = await getAddressesList(userAccessToken);
      if (response.status === 200) {
        setAddressesList(response.data);
      } else if (
        response.status === 400 &&
        response.message === "Invalid token."
      ) {
        setNeedLogin(true);
        setUserAccessToken(null);
        navigate("/login");
      } else if (
        response.status === 404 &&
        response.message === "User not found."
      ) {
        setNeedLogin(true);
        setUserAccessToken(null);
        navigate("/login");
      }
    } catch (error) {
      console.error("Failed to fetch user data:", error);
    }
  };

  const handleUpdateUserDetails = async (updatedDetails) => {
    try {
      const response = await updateUserDetails(userAccessToken, updatedDetails);
      if (response.status === 200) {
        toast.success("اطلاعات کاربری با موفقیت به روز رسانی شد");
      } else if (
        response.status === 400 &&
        response.message === "Invalid token."
      ) {
        setNeedLogin(true);
        setUserAccessToken(null);
        navigate("/login");
      } else if (
        response.status === 404 &&
        response.message === "User not found."
      ) {
        setNeedLogin(true);
        setUserAccessToken(null);
        navigate("/login");
      }
    } catch (error) {
      console.error("Failed to update user details:", error);
    }
  };

  const handleUpdateAddress = async (addressId, updatedAddress) => {
    try {
      const response = await updateAddress(
        userAccessToken,
        addressId,
        updatedAddress
      );
      if (response.status === 200) {
        setAddressesList((prevAddresses) =>
          prevAddresses.map((address) =>
            address.addressId === addressId ? updatedAddress : address
          )
        );
      } else if (
        response.status === 400 &&
        response.message === "Invalid token."
      ) {
        setNeedLogin(true);
        setUserAccessToken(null);
        navigate("/login");
      } else if (
        response.status === 404 &&
        response.message === "User not found."
      ) {
        setNeedLogin(true);
        setUserAccessToken(null);
        navigate("/login");
      }
    } catch (error) {
      console.error("Failed to update address:", error);
    }
  };

  const handleRemoveAddress = async (addressId) => {
    try {
      const response = await removeAddress(userAccessToken, addressId);
      if (response.status === 200) {
        setAddressesList((prevAddresses) =>
          prevAddresses.filter((address) => address.addressId !== addressId)
        );
      } else if (
        response.status === 400 &&
        response.message === "Invalid token."
      ) {
        setNeedLogin(true);
        setUserAccessToken(null);
        navigate("/login");
      } else if (
        response.status === 404 &&
        response.message === "User not found."
      ) {
        setNeedLogin(true);
        setUserAccessToken(null);
        navigate("/login");
      }
    } catch (error) {
      console.error("Failed to remove address:", error);
    }
  };

  const handleCreateAddress = async (newAddress) => {
    try {
      const response = await createAddress(userAccessToken, newAddress);
      if (response.status === 201) {
        try {
          const addressesResponse = await getAddressesList(userAccessToken);
          if (addressesResponse.status === 200) {
            setAddressesList(addressesResponse.data);
          }
        } catch (error) {
          console.error("Failed to fetch user data:", error);
        }
      } else if (
        response.status === 400 &&
        response.message === "Invalid token."
      ) {
        setNeedLogin(true);
        setUserAccessToken(null);
        navigate("/login");
      } else if (
        response.status === 404 &&
        response.message === "User not found."
      ) {
        setNeedLogin(true);
        setUserAccessToken(null);
        navigate("/login");
      }
    } catch (error) {
      console.error("Failed to create address:", error);
    }
  };

  const handleUserDetailsChange = (field, value) => {
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  useEffect(() => {
    if (needLogin || !userAccessToken) {
      setNeedLogin(true);
      setUserAccessToken(null);
      navigate("/login");
    } else {
      apiHandle();
    }
  }, []);

  useEffect(() => {
    setActiveTab(initialTab);
  }, [initialTab]);

  return (
    <>
      <Navbar />
      <div className="container-Dashboard">
        <DashNavBar activeTab={activeTab} activeTabHandler={activeTabHandler} />
        {activeTab === "profile" ? (
          <ProfileDashboard
            userDetails={userDetails}
            onUpdateUserDetails={handleUpdateUserDetails}
            onUserDetailsChange={handleUserDetailsChange}
          />
        ) : activeTab === "orders" ? (
          <OrdersDashboard ordersList={ordersList} />
        ) : activeTab === "addresses" ? (
          <AddressesDashBoard
            addressesList={addressesList}
            handleUpdateAddress={handleUpdateAddress}
            handleRemoveAddress={handleRemoveAddress}
            handleCreateAddress={handleCreateAddress}
          />
        ) : activeTab === "favourites" ? (
          <FavouritesDashboard />
        ) : null}
      </div>
      <Footer />
    </>
  );
}
