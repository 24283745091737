import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import "./Orders.css";
import useStore from "../stores/store";
import { getOrderDetails } from "../scripts/getOrderDetails";
import { verifyPayment } from "../scripts/verifyPayment";

const statusToPersian = {
  awaiting_payment: "در انتظار پرداخت",
  awaiting_confirmation: "در انتظار تایید",
  processing: "در حال آماده سازی",
  shipped: "ارسال شده",
  delivered: "تحویل داده شده",
  cancelled: "لغو شده",
};

const deliveryService = {
  express: "پیک",
  post: "پست پیشتاز",
};

export default function Orders() {
  const userAccessToken = useStore((state) => state.userAccessToken);
  const updateCart = useStore((state) => state.updateCart);
  const [orderDetails, setOrderDetails] = useState(null);
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const orderId = queryParams.get("orderId");
  const authority = queryParams.get("Authority");
  const status = queryParams.get("Status");

  const fetchOrderDetails = async (orderId) => {
    const details = await getOrderDetails(orderId, userAccessToken);
    setOrderDetails(details);
  };

  const verifyOrderPayment = async (orderId, authority) => {
    await verifyPayment(orderId, authority, userAccessToken);
  };

  useEffect(() => {
    if (orderId) {
      fetchOrderDetails(orderId);
      if (authority && status) {
        updateCart([]);
        verifyOrderPayment(orderId, authority);
      }
    }
  }, [orderId, authority, status]);

  return (
    <>
      <Navbar />
      <div className="container-Orders">
        <div className="container-indisder-Orders">
          {orderDetails?.items ? (
            <div className="order-details-Orders">
              {status && (
                <div className="verification-status">
                  <h3>اطلاعات پرداخت سفارش</h3>
                  <p>
                    وضعیت:{" "}
                    {status === "OK"
                      ? "پرداخت با موفقیت انجام شد"
                      : "پرداخت ناموفق"}
                  </p>
                  <p>
                    پیام:{" "}
                    {status === "OK"
                      ? "پرداخت شما با موفقیت انجام شده است و سفارش شما به زودی انجام میشود"
                      : "پرداخت شما ناموفق بوده است ، لطفا با استفاده از دکمه پرداخت ، مجددا پرداخت خود را انجام دهید یا با پشتیبانی تماس بگیرید"}
                  </p>
                  {status !== "OK" && <button>پرداخت مجدد</button>}
                </div>
              )}
              {orderDetails?.status === "awaiting_payment" && !status && (
                <div className="verification-status">
                  <h3>اطلاعات پرداخت سفارش</h3>
                  <p>وضعیت: پرداخت ناموفق</p>
                  <p>
                    پیام: پرداخت شما ناموفق بوده است ، لطفا با استفاده از دکمه
                    پرداخت ، مجددا پرداخت خود را انجام دهید یا با پشتیبانی تماس
                    بگیرید
                  </p>
                  <button>پرداخت مجدد</button>
                </div>
              )}
              <h2>اطلاعات سفارش</h2>
              <p>شماره سفارش: {orderDetails.orderId}</p>
              <p>
                وضعیت:{" "}
                {status === "OK"
                  ? statusToPersian.awaiting_confirmation
                  : statusToPersian[orderDetails.status]}
              </p>
              <p>
                مبلغ کل بدون تخفیف: {orderDetails.totalPrice.toLocaleString()}{" "}
                تومان
              </p>
              <p>
                مبلغ کل با تخفیف:{" "}
                {orderDetails.totalFinalPrice.toLocaleString()} تومان
              </p>
              <p>
                هزینه ارسال: {orderDetails.deliveryPrice.toLocaleString()} تومان
              </p>
              <p>روش ارسال: {deliveryService[orderDetails.deliveryService]}</p>
              <h3>موارد سفارش</h3>
              <ul>
                {orderDetails.items.map((item) => (
                  <li key={item._id}>
                    {item.productName}{" "}
                    {item.variantName ? " - " + item.variantName + " " : null} -
                    تعداد: {item.quantity} - قیمت: {item.price.toLocaleString()}{" "}
                    تومان - قیمت نهایی: {item.finalPrice.toLocaleString()} تومان
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}
