import React from "react";
import "./Footer.css";
import ShopImageShopImageFooter from "./ShopImageFooter";
import Advantages from "./Advantages";
import AboutFooter from "./AboutFooter";

export default function Footer() {
  return (
    <div className="Footer">
      <ShopImageShopImageFooter />
      <Advantages />
      <AboutFooter />
    </div>
  );
}
