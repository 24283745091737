import axios from "axios";

export const verifyPayment = async (orderId, authority, userAccessToken) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/payment/verify`,
      {
        params: {
          orderId,
          Authority: authority,
        },
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
