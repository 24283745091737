import axios from "axios";

export const adminUpdateCategory = async (token, categoryId, categoryData) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_SERVER_URL}/api/admin/categories/update/${categoryId}`,
      categoryData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const adminListCategory = async (token) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/admin/categories/list`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const adminDeleteCategory = async (token, categoryId) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_SERVER_URL}/api/admin/categories/remove/${categoryId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const adminCreateCategory = async (token, categoryData) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/admin/categories/create`,
      categoryData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
