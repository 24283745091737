import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Products.css";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import { getAllProducts } from "../scripts/getAllProducts";
import ProductBoxSlider from "../components/home/ProductBoxSlider";
import Pagination from "../components/products/Pagination";
import { getProductsByCategory } from "../scripts/getProductsByCategory";
import { searchProducts } from "../scripts/searchProducts";

const DEFAULT_PRODUCTS_PER_PAGE = 32;
const MOBILE_PRODUCTS_PER_PAGE = 16;
const DEFAULT_WIDTH = 170;
const DEFAULT_HEIGHT = 257;

export default function Products() {
  const location = useLocation();
  const history = useNavigate();
  const [transformScale, setTransformScale] = useState(1);
  const [newDimensions, setNewDimensions] = useState({
    width: DEFAULT_WIDTH,
    height: DEFAULT_HEIGHT,
  });
  const [productsPerPage, setProductsPerPage] = useState(
    DEFAULT_PRODUCTS_PER_PAGE
  );
  const [currentProducts, setCurrentProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageNotFound, setPageNotFound] = useState(false);

  const fetchProducts = async (category, query, page) => {
    let response;
    if (query) {
      response = await searchProducts(query, productsPerPage, page);
    } else if (category) {
      response = await getProductsByCategory(category, productsPerPage, page);
    } else {
      response = await getAllProducts(productsPerPage, page);
    }

    if (response.products.length === 0) {
      setPageNotFound(true);
    } else {
      setPageNotFound(false);
      setCurrentProducts(response.products);
      setTotalPages(response.pages);
    }
  };

  useEffect(() => {
    const updateProductsPerPage = () => {
      if (window.innerWidth <= 479) {
        setProductsPerPage(MOBILE_PRODUCTS_PER_PAGE);
      } else {
        setProductsPerPage(DEFAULT_PRODUCTS_PER_PAGE);
      }
    };

    updateProductsPerPage();
    window.addEventListener("resize", updateProductsPerPage);

    return () => {
      window.removeEventListener("resize", updateProductsPerPage);
    };
  }, []);

  useEffect(() => {
    const containerWidthElement = document.querySelector(
      ".container-Products"
    )?.offsetWidth;
    let containerWidth = containerWidthElement - 40;
    const minBoxes = 2;
    const scale =
      containerWidth < DEFAULT_WIDTH * minBoxes
        ? containerWidth / (DEFAULT_WIDTH * minBoxes)
        : 1;
    setTransformScale(scale);

    if (scale < 1) {
      const newWidth = containerWidth / minBoxes;
      const newHeight = (newWidth / DEFAULT_WIDTH) * DEFAULT_HEIGHT;
      setNewDimensions({ width: newWidth, height: newHeight });
    } else {
      setNewDimensions({ width: DEFAULT_WIDTH, height: DEFAULT_HEIGHT });
    }

    const containerPagesElement = document.querySelector(
      ".container-pages-Products"
    );
    if (window.innerWidth < 370 && containerPagesElement) {
      const containerPagesWidth = containerPagesElement.offsetWidth;
      const containerPagesScale =
        containerPagesWidth > containerWidth
          ? containerWidth / containerPagesWidth
          : 1;
      containerPagesElement.style.transform = `scale(${containerPagesScale})`;
    }
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const category = queryParams.get("category");
    const query = queryParams.get("query");
    const page = parseInt(queryParams.get("page")) || 1;
    setCurrentPage(page);
    fetchProducts(category, query, page);
  }, [location.search, productsPerPage]);

  const handlePageChange = (newPage) => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("page", newPage);
    history({ search: queryParams.toString() });
  };

  return (
    <>
      <Navbar />
      <div className="Products">
        {pageNotFound ? (
          <div className="page-not-found-Products">
            404
            <br />
            محصول یا صفحه مورد نظر یافت نشد
          </div>
        ) : (
          <>
            <div className="container-Products">
              {currentProducts.map((product, index) => (
                <ProductBoxSlider
                  key={index}
                  slug={product.slug}
                  imageSource={product.mainImage}
                  imageAltText={product.imageAltText}
                  productName={product.name}
                  productMainCategory={product.category}
                  productPrice={product.price}
                  offerLabel={product.salePrice ? true : false}
                  productNewPrice={product.salePrice}
                  transformScale={transformScale}
                  newWidth={newDimensions.width}
                  newHeight={newDimensions.height}
                  isAwalaible={product.quantity !== 0}
                />
              ))}
            </div>
            <div className="container-pages-Products">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </>
        )}
      </div>
      <Footer />
    </>
  );
}
