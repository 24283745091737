import React from "react";
import "./FavouritesDashboard.css";

export default function FavouritesDashboard() {
  return (
    <div className="container-FavouritesDashboard">
      <img
        className="icon-FavouritesDashboard"
        src="/assets/icons/favourites.png"
        alt="Favourites"
      />
      <p className="text-FavouritesDashboard">
        هنوز هیچ محصولی رو به علاقه مندی هات اضافه نکردی !
      </p>
    </div>
  );
}
