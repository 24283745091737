import React, { useState, useEffect } from "react";
import {
  adminListOrders,
  adminDeleteOrder,
  adminUpdateOrderStatus,
} from "../../scripts/admin/adminOrders";
import useStore from "../../stores/store";
import "./AdminOrders.css"; // Assuming you will create a CSS file for styling
import formatPrice from "../../scripts/formatPrice";
import { toast } from "react-toastify";

const statusTranslations = {
  awaiting_payment: "در انتظار پرداخت",
  awaiting_confirmation: "در انتظار تایید",
  processing: "در حال پردازش",
  shipped: "ارسال شده",
  delivered: "تحویل داده شده",
  cancelled: "لغو شده",
};

export default function AdminOrders() {
  const [orders, setOrders] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const userAccessToken = useStore((state) => state.userAccessToken);

  useEffect(() => {
    const fetchOrders = async () => {
      const token = userAccessToken;
      const response = await adminListOrders(token, currentPage);
      if (response.success) {
        setOrders(response.data.orders);
        setTotalPages(response.data.totalPages);
      } else {
        toast.error("خطا در دریافت سفارشات");
      }
    };

    fetchOrders();
  }, [userAccessToken, currentPage]);

  const sortOrders = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }

    const sortedOrders = [...orders].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setOrders(sortedOrders);
    setSortConfig({ key, direction });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleViewOrder = (order) => {
    setSelectedOrder(order);
  };

  const handleDeleteOrder = async (orderId) => {
    const token = userAccessToken;
    const response = await adminDeleteOrder(token, orderId);
    if (response.success) {
      setOrders(orders.filter((order) => order._id !== orderId));
    } else {
      toast.error("خطا در حذف سفارش");
    }
  };

  const handleStatusChange = async (orderId, newStatus) => {
    const token = userAccessToken;
    const response = await adminUpdateOrderStatus(token, orderId, newStatus);
    if (response.success) {
      setOrders(
        orders.map((order) =>
          order._id === orderId ? { ...order, status: newStatus } : order
        )
      );
      if (selectedOrder && selectedOrder._id === orderId) {
        setSelectedOrder({ ...selectedOrder, status: newStatus });
      }
    } else {
      toast.error("خطا در بروزرسانی وضعیت سفارش");
    }
  };

  return (
    <div className="admin-orders-AdminPage" style={{ direction: "rtl" }}>
      <div className="header-AdminPage" style={{ direction: "ltr" }}>
        <h2>سفارشات</h2>
      </div>
      <table className="orders-table-AdminPage">
        <thead>
          <tr>
            <th onClick={() => sortOrders("orderId")}>
              شماره سفارش{" "}
              {sortConfig.key === "orderId"
                ? sortConfig.direction === "ascending"
                  ? "↑"
                  : "↓"
                : ""}
            </th>
            <th onClick={() => sortOrders("userDetails.name")}>
              نام سفارش دهنده{" "}
              {sortConfig.key === "userDetails.name"
                ? sortConfig.direction === "ascending"
                  ? "↑"
                  : "↓"
                : ""}
            </th>
            <th onClick={() => sortOrders("status")}>
              وضعیت{" "}
              {sortConfig.key === "status"
                ? sortConfig.direction === "ascending"
                  ? "↑"
                  : "↓"
                : ""}
            </th>
            <th onClick={() => sortOrders("updatedAt")}>
              آخرین بروزرسانی{" "}
              {sortConfig.key === "updatedAt"
                ? sortConfig.direction === "ascending"
                  ? "↑"
                  : "↓"
                : ""}
            </th>
            <th>عملیات</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <tr key={order._id}>
              <td>{order.orderId}</td>
              <td>
                {order.userDetails.name} {order.userDetails.lastName}
              </td>
              <td>{statusTranslations[order.status]}</td>
              <td style={{ direction: "ltr" }}>
                {new Date(order.createdAt).toLocaleString("fa-IR")}
              </td>
              <td>
                <button
                  className="view-btn-AdminPage"
                  onClick={() => handleViewOrder(order)}
                >
                  مشاهده
                </button>
                <button
                  className="delete-btn-AdminPage"
                  onClick={() => handleDeleteOrder(order._id)}
                >
                  حذف
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination-AdminPage">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => handlePageChange(index + 1)}
            className={currentPage === index + 1 ? "active" : ""}
          >
            {index + 1}
          </button>
        ))}
      </div>
      {selectedOrder && (
        <div className="modal-AdminPage">
          <div
            className="modal-content-AdminPage"
            style={{
              direction: "rtl",
              textAlign: "right",
              width: "auto",
              maxWidth: "80%",
              overflowY: "scroll",
              maxHeight: "80%",
            }}
          >
            <h3>جزئیات سفارش</h3>
            <p>شماره سفارش: {selectedOrder.orderId}</p>
            <p>نام: {selectedOrder.userDetails.name}</p>
            <p>نام خانوادگی: {selectedOrder.userDetails.lastName}</p>
            <p>شماره تلفن: {selectedOrder.userDetails.phoneNumber}</p>
            <p>
              وضعیت:
              <select
                value={selectedOrder.status}
                style={{ fontFamily: "Vazir" }}
                onChange={(e) =>
                  handleStatusChange(selectedOrder._id, e.target.value)
                }
              >
                {Object.keys(statusTranslations).map((status) => (
                  <option key={status} value={status}>
                    {statusTranslations[status]}
                  </option>
                ))}
              </select>
            </p>
            <p>
              تاریخ سفارش:{" "}
              {new Date(selectedOrder.createdAt).toLocaleString("fa-IR")}
            </p>
            <p>
              آخرین بروزرسانی:{" "}
              {new Date(selectedOrder.updatedAt).toLocaleString("fa-IR")}
            </p>
            <p>استان: {selectedOrder.addressDetails.state}</p>
            <p>شهر: {selectedOrder.addressDetails.city}</p>
            <p>آدرس: {selectedOrder.addressDetails.streetAddress}</p>
            <p>کد پستی: {selectedOrder.addressDetails.postalCode}</p>
            <p>قیمت کل: {formatPrice(selectedOrder.totalFinalPrice)} تومان</p>
            <p>محصولات:</p>
            <ul>
              {selectedOrder.items.map((item) => (
                <li key={item._id}>
                  {item.productName}{" "}
                  {item.useVariant ? "- نوع: " + `${item.variantName}` : null} -
                  تعداد: {item.quantity} - قیمت جزء:{" "}
                  {formatPrice(item.finalPrice ? item.finalPrice : item.price)}{" "}
                  تومان
                </li>
              ))}
            </ul>
            <button
              style={{ fontFamily: "Vazir" }}
              onClick={() => setSelectedOrder(null)}
            >
              بستن
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
