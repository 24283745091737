import React from "react";
import "./ProductBoxSlider.css";
import formatPrice from "../../scripts/formatPrice";
import { useNavigate } from "react-router-dom";

const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  }
  return text;
};

export default function ProductBoxSlider({
  slug,
  imageSource,
  imageAltText,
  productName,
  productMainCategory,
  productPrice,
  offerLabel,
  productNewPrice,
  transformScale,
  newWidth,
  newHeight,
  isDragging, // New prop
  isAwalaible,
}) {
  const navigate = useNavigate();
  return (
    <div
      style={{
        width: `${newWidth}px`,
        height: `${newHeight}px`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        className="ProductBoxSlider"
        draggable="false"
        style={{ transform: `scale(${transformScale})` }}
        onClick={() => {
          if (!isDragging) {
            navigate(`/products/${slug}`);
          }
        }}
      >
        {offerLabel && (
          <div className="offer-label-ProductBoxSlider" draggable="false">
            <p className="text-offer-label-ProductBoxSlider">تخفیف ویژه</p>
          </div>
        )}
        <img
          className="image-ProductBoxSlider"
          src={`${process.env.REACT_APP_SERVER_URL}/api${imageSource}`}
          draggable="false"
          alt={imageAltText}
        />
        <div className="info-ProductBoxSlider" draggable="false">
          <div className="details-ProductBoxSlider" draggable="false">
            <p className="name-ProductBoxSlider" title={productName}>
              {truncateText(productName, 23)}
            </p>
            <p
              className="main-catregory-ProductBoxSlider"
              title={productMainCategory}
            >
              {truncateText(productMainCategory, 25)}
            </p>
          </div>
          {isAwalaible ? (
            offerLabel ? (
              <div
                className="buy-button-ProductBoxSlider"
                style={{ background: "linear-gradient(to right, #fa2 , #f70)" }}
                draggable="false"
              >
                <p className="old-price-ProductBoxSlider">
                  {formatPrice(productPrice)} تومان
                </p>
                <p className="price-ProductBoxSlider">
                  {formatPrice(productNewPrice)} تومان
                </p>
              </div>
            ) : (
              <div className="buy-button-ProductBoxSlider" draggable="false">
                <p className="price-ProductBoxSlider">
                  {formatPrice(productPrice)} تومان
                </p>
              </div>
            )
          ) : (
            <div
              className="buy-button-ProductBoxSlider"
              style={{ background: "#c0c0c0" }}
              draggable="false"
            >
              <p className="price-ProductBoxSlider">ناموجود</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
