import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "./MainSlider.css";
import { useNavigate } from "react-router-dom";

const Slide = styled.div`
  display: flex;
  transition: transform 0.5s ease-in-out;
  transform: ${({ activeIndex }) => `translateX(-${activeIndex * 100}%)`};
  height: auto;
`;

const Dot = styled.span`
  width: 50px;
  height: 4px;
  margin: 0 5px;
  background-color: ${({ active }) => (active ? "#ff3344" : "#ffffff")};
  border-radius: 2px;
  cursor: pointer;
`;

const images = [
  {
    src: "/assets/images/slider-image-1.jpg",
    alt: "Slide 1",
    link: "/products?category=محصولات-razer",
  },
  {
    src: "/assets/images/slider-image-2.jpg",
    alt: "Slide 2",
    link: "/products?category=کنسول-بازی-PlayStation-5",
  },
  {
    src: "/assets/images/slider-image-3.jpg",
    alt: "Slide 3",
    link: "/products?category=کنترلر-و-DualSense-برای-PS5",
  },
];

const MainSlider = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [dragStart, setDragStart] = useState(null);
  const navigate = useNavigate();

  const nextSlide = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setActiveIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const goToSlide = (index) => {
    setActiveIndex(index);
  };

  const handleDragStart = (e) => {
    setDragStart(e.clientX || e.touches[0].clientX);
  };

  const handleDragEnd = (e) => {
    const dragEnd = e.clientX || e.changedTouches[0].clientX;
    const dragDistance = dragEnd - dragStart;
    if (dragDistance > 50) {
      prevSlide();
    } else if (dragDistance < -50) {
      nextSlide();
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      prevSlide();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="MainSlider">
      <img
        src="/assets/icons/left-arrow.png"
        className="left-arrow-MainSlider"
        onClick={prevSlide}
        alt="Previous Slide"
      />
      <Slide
        activeIndex={activeIndex}
        className="box-MainSlider"
        draggable
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        {images.map((image, index) => (
          <img
            className="image-MainSlider"
            src={image.src}
            alt={image.alt}
            key={index}
            draggable="false"
            onMouseDownown={() => false}
            onClick={() => {
              navigate(image.link);
            }}
          />
        ))}
      </Slide>

      <img
        src="/assets/icons/right-arrow.png"
        className="right-arrow-MainSlider"
        onClick={nextSlide}
        alt="Next Slide"
      />
      <div className="dots-MainSlider">
        {images.map((_, index) => (
          <Dot
            className="dot-MainSlider"
            key={index}
            active={index === activeIndex}
            onClick={() => goToSlide(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default MainSlider;
