import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./Product.css";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import MainDetailsProduct from "../components/product/MainDetailsProduct";
import ImagesProduct from "../components/product/ImagesProduct";
import BuyBoxProduct from "../components/product/BuyBoxProduct";
import ProductSlider from "../components/home/ProductSlider";
import MidBoxProduct from "../components/product/MidBoxProduct";
import { getProductDetails } from "../scripts/getProductDetails";
import { getProductsForMenuRandom } from "../scripts/getProductsForMenu";

export default function Product() {
  const location = useLocation();
  const slug = location.pathname.split("/").pop();
  const [product, setProduct] = useState(null);
  const [randomProducts, setRandomProducts] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    const fetchProductDetails = async () => {
      const productData = await getProductDetails(slug);
      setProduct(productData);
      if (productData.variants && productData.variants.length > 0) {
        const availableVariant = productData.variants.find(
          (variant) => variant.stock !== 0
        );
        setSelectedVariant(availableVariant || productData.variants[0]); // Default to first available variant
      } else {
        setSelectedVariant(null); // Handle products without variants
      }

      if (productData.categories && productData.categories.length > 0) {
        const categoryName = productData.categories[0];
        const random = await getProductsForMenuRandom(categoryName);
        setRandomProducts(random);
      }
    };
    fetchProductDetails();
  }, [slug]);

  const handleVariantChange = (variant) => {
    setSelectedVariant(variant);
  };

  if (!product) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Navbar />
      <div className="Product">
        <div className="main-box-Product">
          <BuyBoxProduct
            offerLabel={!!selectedVariant?.salePrice}
            productPrice={selectedVariant?.price || product.price}
            productNewPrice={selectedVariant?.salePrice || product.salePrice}
            productId={product._id}
            variantId={selectedVariant?._id}
            variantName={selectedVariant?.name}
            productImage={product.images[0]}
            productName={product.name}
            productStock={product.stock}
            selectedVariant={selectedVariant} // Pass the selectedVariant object
          />
          <MainDetailsProduct
            productName={product.name}
            productDescription={product.shortDescription}
            productCategories={product.categories} // Pass all categories
            variants={product.variants}
            selectedVariant={selectedVariant}
            onVariantChange={handleVariantChange}
          />
          <ImagesProduct images={product.images} />
        </div>
      </div>
      <MidBoxProduct description={product.description} />
      <ProductSlider categoryTitle="محصولات مرتبط" products={randomProducts} />
      <Footer />
    </>
  );
}
