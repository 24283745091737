import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  adminListProducts,
  adminDeleteProduct,
} from "../../scripts/admin/adminProducts";
import useStore from "../../stores/store";
import "./AdminProducts.css";
import formatPrice from "../../scripts/formatPrice";
import { toast } from "react-toastify";

export default function AdminProducts() {
  const [products, setProducts] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const userAccessToken = useStore((state) => state.userAccessToken);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      const token = userAccessToken;
      const response = await adminListProducts(token, currentPage);
      if (response.success) {
        setProducts(response.data.products);
        setTotalPages(response.data.totalPages);
      } else {
        toast.error("خطا در دریافت محصولات");
      }
    };

    fetchProducts();
  }, [userAccessToken, currentPage]);

  const sortProducts = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }

    const sortedProducts = [...products].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setProducts(sortedProducts);
    setSortConfig({ key, direction });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDeleteProduct = async (productId) => {
    const token = userAccessToken;
    const response = await adminDeleteProduct(token, productId);
    if (response.success) {
      setProducts(products.filter((product) => product._id !== productId));
    } else {
      toast.error("خطا در حذف محصول");
    }
  };

  return (
    <div className="admin-products-AdminPage">
      <div className="header-AdminPage">
        <h2>محصولات</h2>
        <button
          className="new-product-btn-AdminPage"
          onClick={() => navigate("/admin/products/new")}
        >
          محصول جدید
        </button>
      </div>
      <table className="products-table-AdminPage">
        <thead>
          <tr>
            <th>نام</th>
            <th onClick={() => sortProducts("price")}>
              قیمت{" "}
              {sortConfig.key === "price"
                ? sortConfig.direction === "ascending"
                  ? "↑"
                  : "↓"
                : ""}
            </th>
            <th onClick={() => sortProducts("stock")}>
              موجودی{" "}
              {sortConfig.key === "stock"
                ? sortConfig.direction === "ascending"
                  ? "↑"
                  : "↓"
                : ""}
            </th>
            <th>عملیات</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product) => (
            <tr key={product._id}>
              <td>
                <Link
                  to={`/products/${product.slug}`}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  {product.name}
                </Link>
              </td>
              <td>{formatPrice(product.price)} تومان</td>
              <td>{product.stock}</td>
              <td>
                <button
                  className="edit-btn-AdminPage"
                  onClick={() =>
                    navigate(`/admin/products/edit/${product._id}`)
                  }
                >
                  ویرایش
                </button>
                <button
                  className="delete-btn-AdminPage"
                  onClick={() => handleDeleteProduct(product._id)}
                >
                  حذف
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination-AdminPage">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => handlePageChange(index + 1)}
            className={currentPage === index + 1 ? "active" : ""}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
}
