import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

const defaultState = {
  needLogin: true,
  userAccessToken: null,
  userCart: [],
  isAdmin: false, // Add this line
};

const useStore = create(
  persist(
    (set) => ({
      ...defaultState,
      setNeedLogin: (value) => set({ needLogin: value }),
      setUserAccessToken: (value) => set({ userAccessToken: value }),
      setIsAdmin: (value) => set({ isAdmin: value }), // Add this line
      addToCart: (product) =>
        set((state) => {
          const existingProduct = state.userCart.find(
            (item) =>
              item.id === product.id && item.variantId === product.variantId
          );
          if (existingProduct) {
            return {
              userCart: state.userCart.map((item) =>
                item.id === product.id && item.variantId === product.variantId
                  ? { ...item, quantity: item.quantity + 1 }
                  : item
              ),
            };
          } else {
            return {
              userCart: [...state.userCart, { ...product, quantity: 1 }],
            };
          }
        }),
      removeFromCart: (product) =>
        set((state) => {
          const existingProduct = state.userCart.find(
            (item) =>
              item.id === product.id && item.variantId === product.variantId
          );
          if (existingProduct) {
            if (existingProduct.quantity > 1) {
              return {
                userCart: state.userCart.map((item) =>
                  item.id === product.id && item.variantId === product.variantId
                    ? { ...item, quantity: item.quantity - 1 }
                    : item
                ),
              };
            } else {
              return {
                userCart: state.userCart.filter(
                  (item) =>
                    !(
                      item.id === product.id &&
                      item.variantId === product.variantId
                    )
                ),
              };
            }
          }
          return state;
        }),
      updateCart: (products) =>
        set({
          userCart: products.map((product) => ({
            id: product.productId,
            variantId: product.variantId,
            variantName: product.variantSelectedName,
            quantity: product.quantity,
            productPrice: product.useVariant
              ? product.variantSelectedSalePrice
                ? product.variantSelectedSalePrice
                : product.variantSelectedPrice
              : product.salePrice
              ? product.salePrice
              : product.price,
            imageObject: {
              url: product.mainImageUrl,
              altText: product.productName,
            },
            name: product.productName,
          })),
        }),
      resetAll: () => set(defaultState),
    }),
    {
      name: "userData", // Unique name for the persisted data
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => ({
        needLogin: state.needLogin,
        userAccessToken: state.userAccessToken,
        userCart: state.userCart,
        isAdmin: state.isAdmin, // Add this line
      }),
    }
  )
);

export default useStore;
