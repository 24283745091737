import React, { useEffect, useState } from "react";
import "./Navbar.css";
import useStore from "../../stores/store";
import NavbarMenu from "./NavbarMenu";
import { Link, useNavigate, useLocation } from "react-router-dom";
import menus from "../../stores/menus";

export default function Navbar() {
  const userCart = useStore((state) => state.userCart);
  const needLogin = useStore((state) => state.needLogin);
  const userAccessToken = useStore((state) => state.userAccessToken);
  let [searchBarFocus, setSearchBarFocus] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const [openMegaMenuIndex, setOpenMegaMenuIndex] = useState(null);
  const [openSubMenuIndex, setOpenSubMenuIndex] = useState(null);

  const toggleSlider = () => {
    setIsSliderOpen(!isSliderOpen);
  };

  const toggleMegaMenu = (index) => {
    setOpenMegaMenuIndex(openMegaMenuIndex === index ? null : index);
  };

  const toggleSubMenu = (index) => {
    setOpenSubMenuIndex(openSubMenuIndex === index ? null : index);
  };

  // fake comment

  // Function to handle scroll events
  const handleScroll = () => {
    setIsSticky(window.scrollY > 50); // Adjust the value as needed
  };

  // Add and clean up scroll event listener
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setIsSliderOpen(false);
  }, [location]);

  const handleSearch = (event) => {
    if (event.key === "Enter") {
      const query = event.target.value;
      navigate(`/products?query=${query}`);
    }
  };

  return (
    <>
      {windowSize.width > 1024 ? (
        <>
          <div className="top-bar-Navbar">
            <div className="top-bar-box-Navbar">
              <div className="phone-number-box-Navbar">
                <img
                  className="phone-icon-Navbar"
                  src="/assets/icons/phone.png"
                  alt="phone"
                />
                <a className="phone-number-Navbar" href="tel:09129578265">
                  09129578265
                </a>
              </div>
              <div className="logo-box-Navbar">
                <Link className="logo-Navbar" to="/">
                  <img
                    className="logo-Navbar"
                    src="/assets/logo/ps4tehransar-logo.png"
                    alt="logo"
                  />
                </Link>
              </div>
              <div className="instagram-box-Navbar">
                <a
                  className="instagram-Navbar"
                  href="https://www.instagram.com/ps4_tehransar"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ps4_tehransar
                </a>
                <img
                  className="instagram-icon-Navbar"
                  src="/assets/icons/instagram.png"
                  alt="instagram"
                />
              </div>
            </div>
          </div>
          <div className={`bottom-bar-Navbar ${isSticky ? "sticky" : ""}`}>
            <div className="bottom-bar-box-Navbar">
              <div className="left-box-bottom-bar-Navbar">
                {/* {needLogin ? (
                  <div
                    onClick={() => navigate("/login")}
                    className="login-box-Navbar"
                  >
                    <p className="login-text-Navbar">ورود یا ثبت نام</p>
                    <img
                      className="login-icon-Navbar"
                      src="/assets/icons/login.png"
                    />
                  </div>
                ) : (
                  <> */}
                <div
                  className="cart-box-Navbar"
                  onClick={() => navigate("/cart")}
                >
                  <img
                    className="cart-icon-Navbar"
                    src="/assets/icons/shopping-cart.png"
                    alt="cart"
                  />
                  {userCart && userCart.length > 0 && (
                    <div className="cart-quantity-Navbar"></div>
                  )}
                </div>
                <div
                  className="dashboard-box-Navbar"
                  onClick={() => {
                    if (needLogin || !userAccessToken) {
                      navigate("/login");
                    } else {
                      navigate("/dashboard");
                    }
                  }}
                >
                  <img
                    className="dashboard-icon-Navbar"
                    src="/assets/icons/people.png"
                    alt="dashboard"
                  />
                </div>
                {/* </>
                )} */}
              </div>
              <NavbarMenu isSticky={isSticky} />
              <div className="right-box-bottom-bar-Navbar">
                <div
                  className={
                    searchBarFocus
                      ? "search-box-Navbar search-box-active-Navbar"
                      : "search-box-Navbar"
                  }
                >
                  <input
                    className="search-input-Navbar"
                    type="text"
                    placeholder="جستجو . . ."
                    onFocus={() => setSearchBarFocus(true)}
                    onBlur={() => setSearchBarFocus(false)}
                    onKeyDown={handleSearch}
                  />
                  <img
                    className="search-icon-Navbar"
                    src="/assets/icons/search.png"
                    onClick={() => {
                      setSearchBarFocus(false);
                    }}
                    alt="search"
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : windowSize.width <= 1024 ? (
        <div className="container-320-Navbar">
          <div className="top-bar-Navbar">
            <div className="top-bar-box-Navbar">
              <Link className="logo-Navbar-container-Navbar" to="/">
                <img
                  className="logo-Navbar"
                  src="/assets/logo/ps4tehransar-logo.png"
                  alt="logo"
                />
              </Link>
              <div className="menu-and-cart-container-Navbar">
                <Link className="cart-icon-container-Navbar" to="/cart">
                  <img
                    className="cart-icon-Navbar"
                    src="/assets/icons/shopping-cart.png"
                    alt="cart"
                  />
                  {userCart && userCart.length > 0 && (
                    <div className="cart-quantity-Navbar"></div>
                  )}
                </Link>
                <img
                  className="menu-icon-Navbar"
                  src={
                    !isSliderOpen
                      ? "/assets/icons/menu.png"
                      : "/assets/icons/close.png"
                  }
                  onClick={toggleSlider}
                  alt="menu"
                />
              </div>
            </div>
          </div>
          <div className="bottom-bar-Navbar">
            <div className="search-box-Navbar ">
              <input
                className="search-input-Navbar"
                type="text"
                placeholder="کالای مورد نظر خود را جستجو کنید . . ."
                onFocus={() => setSearchBarFocus(true)}
                onBlur={() => setSearchBarFocus(false)}
                onKeyDown={handleSearch}
              />
              <img
                className="search-icon-Navbar"
                src="/assets/icons/search.png"
                onClick={() => {
                  setSearchBarFocus(false);
                }}
                alt="search"
              />
            </div>
          </div>
          {isSliderOpen && (
            <div className="slider-menu-Navbar">
              {/* Mega menu content goes here */}
              <Link className="slider-menu-item-Navbar" to="/">
                <p className="menu-text-Navbar">خانه</p>
              </Link>
              <div
                className="slider-menu-item-Navbar"
                onClick={() => toggleMegaMenu(0)} // Pass the correct index here
              >
                <p className="menu-text-Navbar">محصولات</p>
              </div>
              {openMegaMenuIndex === 0 && (
                <div className="mega-menu-Navbar">
                  {menus.map((menu, index) => (
                    <div key={index} style={{ width: "100%" }}>
                      <div
                        className="slider-submenu-item-Navbar"
                        onClick={() => toggleSubMenu(index)}
                      >
                        <p className="submenu-text-Navbar">{menu.title}</p>
                      </div>
                      {openSubMenuIndex === index && (
                        <div className="subchild-menu-Navbar">
                          {menu.submenus.map((submenu, subIndex) => (
                            <Link
                              key={subIndex}
                              className="slider-subchild-item-Navbar"
                              to={`/products?category=${submenu.url}`}
                            >
                              <p className="subchild-text-Navbar">
                                {submenu.title}
                              </p>
                            </Link>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
              <Link className="slider-menu-item-Navbar" to="/dashboard">
                <p className="menu-text-Navbar">حساب کاربری</p>
              </Link>
              <Link className="slider-menu-item-Navbar" to="/cart">
                <p className="menu-text-Navbar">سبد خرید</p>
              </Link>
              <Link className="slider-menu-item-Navbar" to="/about-us">
                <p className="menu-text-Navbar">درباره ما</p>
              </Link>
            </div>
          )}
        </div>
      ) : null}
    </>
  );
}
