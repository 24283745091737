import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  adminGetProductDetails,
  adminUpdateProduct,
  adminCreateProduct,
} from "../../scripts/admin/adminProducts";
import useStore from "../../stores/store";
import "./ProductForm.css";
import { numberToWords } from "@persian-tools/persian-tools";
import { toast } from "react-toastify";

export default function ProductForm() {
  const { productId } = useParams();
  const [product, setProduct] = useState({
    name: "",
    slug: "",
    mainImage: "",
    images: [],
    shortDescription: "",
    description: "",
    stock: 0,
    categories: [],
    price: 0,
    isOnOffer: false,
    salePrice: 0,
    saleStartDate: "",
    saleEndDate: "",
    isActive: true,
    variants: [],
  });
  const userAccessToken = useStore((state) => state.userAccessToken);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProductDetails = async () => {
      if (productId) {
        const token = userAccessToken;
        const response = await adminGetProductDetails(token, productId);
        if (response.success) {
          const productData = response.data;
          setProduct({
            name: productData.name,
            slug: productData.slug,
            mainImage: productData.mainImage,
            images: productData.images,
            shortDescription: productData.shortDescription,
            description: productData.description,
            stock: productData.stock,
            categories: productData.categories,
            price: productData.price,
            isOnOffer: productData.salePrice > 0,
            salePrice: productData.salePrice,
            saleStartDate: productData.saleStartDate?.split("T")[0],
            saleEndDate: productData.saleEndDate?.split("T")[0],
            isActive: productData.isActive,
            variants: productData.variants.map((variant) => ({
              ...variant,
              saleStartDate: variant.saleStartDate?.split("T")[0],
              saleEndDate: variant.saleEndDate?.split("T")[0],
            })),
          });
        } else {
          toast.error("خطا در دریافت اطلاعات محصول");
        }
      }
    };

    fetchProductDetails();
  }, [productId, userAccessToken]);

  useEffect(() => {
    const generateSlug = (name) => {
      return `${name.trim().replace(/\s+/g, "-")}`;
    };
    setProduct((prevProduct) => ({
      ...prevProduct,
      slug: generateSlug(prevProduct.name),
    }));
  }, [product.name]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProduct({ ...product, [name]: value });
  };

  const handleVariantChange = (index, e) => {
    const { name, value } = e.target;
    const newVariants = [...product.variants];
    newVariants[index] = { ...newVariants[index], [name]: value };
    setProduct({ ...product, variants: newVariants });
  };

  const addVariant = () => {
    setProduct({
      ...product,
      variants: [
        ...product.variants,
        {
          name: "",
          stock: 0,
          price: 0,
          salePrice: 0,
          saleStartDate: "",
          saleEndDate: "",
          isActive: true,
        },
      ],
    });
  };

  const removeVariant = (index) => {
    setProduct({
      ...product,
      variants: product.variants.filter((_, i) => i !== index),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = userAccessToken;
    let response;
    if (productId) {
      response = await adminUpdateProduct(token, productId, product);
    } else {
      response = await adminCreateProduct(token, product);
    }
    if (response.success) {
      toast.success("محصول با موفقیت ذخیره شد");
      navigate("/admin/products");
    } else {
      toast.error("خطا در ذخیره محصول");
    }
  };

  return (
    <div className="product-form-AdminPage">
      <h2>{productId ? "ویرایش محصول" : "محصول جدید"}</h2>
      <form onSubmit={handleSubmit}>
        <label>
          نام محصول:
          <input
            type="text"
            name="name"
            value={product.name}
            onChange={handleInputChange}
          />
        </label>
        <label>
          اسلاگ محصول:
          <div
            style={{
              direction: "ltr",
              padding: "10px",
              marginTop: "5px",
              border: "1px solid #ddd",
              borderRadius: "4px",
              width: "100%",
              boxSizing: "border-box",
              fontSize: "14px",
              fontFamily: "Vazir",
            }}
          >
            https://ps4tehransar.ir/
            <span style={{ fontWeight: "bold" }}>{product.slug}</span>
          </div>
        </label>
        <label>
          تصویر اصلی محصول:
          <input
            style={{ direction: "ltr" }}
            type="text"
            name="mainImage"
            value={product.mainImage}
            onChange={handleInputChange}
          />
        </label>
        <label>
          تصاویر محصول:
          <input
            style={{ direction: "ltr" }}
            type="text"
            name="images"
            value={product.images.join(", ")}
            onChange={(e) =>
              setProduct({ ...product, images: e.target.value.split(", ") })
            }
          />
        </label>
        <label>
          توضیحات کوتاه:
          <textarea
            name="shortDescription"
            style={{ fontFamily: "Vazir-FD-Wol" }}
            value={product.shortDescription}
            onChange={handleInputChange}
          />
        </label>
        <label>
          توضیحات اصلی:
          <textarea
            name="description"
            value={product.description}
            onChange={handleInputChange}
            style={{
              minHeight: "300px",

              fontFamily: "Vazir-FD-Wol",
            }}
          />
        </label>
        <label>
          تعداد موجودی در انبار:
          <input
            type="number"
            name="stock"
            value={product.stock}
            onChange={handleInputChange}
          />
        </label>
        <label>
          دسته‌بندی‌ها:
          <input
            style={{ direction: "ltr" }}
            type="text"
            name="categories"
            value={product.categories.join(", ")}
            onChange={(e) =>
              setProduct({ ...product, categories: e.target.value.split(", ") })
            }
          />
        </label>
        <label>
          قیمت: {numberToWords(product.price)} تومان
          <input
            type="number"
            name="price"
            value={product.price}
            onChange={handleInputChange}
          />
        </label>
        <label
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            alignItems: "center",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <input
            type="checkbox"
            name="isOnOffer"
            style={{ width: "20px" }}
            checked={product.isOnOffer}
            onChange={(e) =>
              setProduct({ ...product, isOnOffer: e.target.checked })
            }
          />
          <span style={{ width: "150px", marginRight: "0px" }}>
            تخفیف فعال است؟
          </span>
        </label>
        {product.isOnOffer && (
          <>
            <label>
              قیمت در تخفیف: {numberToWords(product.salePrice)} تومان
              <input
                type="number"
                name="salePrice"
                value={product.salePrice}
                onChange={handleInputChange}
              />
            </label>
            <label>
              تاریخ شروع تخفیف:
              <input
                type="date"
                name="saleStartDate"
                value={product.saleStartDate}
                onChange={handleInputChange}
              />
            </label>
            <label>
              تاریخ پایان تخفیف:
              <input
                type="date"
                name="saleEndDate"
                value={product.saleEndDate}
                onChange={handleInputChange}
              />
            </label>
          </>
        )}
        <label
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            alignItems: "center",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <input
            type="checkbox"
            name="isActive"
            style={{ width: "20px" }}
            checked={product.isActive}
            onChange={(e) =>
              setProduct({ ...product, isActive: e.target.checked })
            }
          />
          <span style={{ width: "150px", marginRight: "0px" }}>
            محصول فعال است؟
          </span>
        </label>
        <h3>تنوع‌ها</h3>

        {product.variants.map((variant, index) => (
          <div key={index} className="variant-AdminPage">
            <label>
              نام تنوع:
              <input
                type="text"
                name="name"
                value={variant.name}
                onChange={(e) => handleVariantChange(index, e)}
                style={{ fontFamily: "Vazir-FD-Wol" }}
              />
            </label>
            <label>
              تعداد موجودی تنوع در انبار:
              <input
                type="number"
                name="stock"
                value={variant.stock}
                onChange={(e) => handleVariantChange(index, e)}
                style={{ fontFamily: "Vazir-FD-Wol" }}
              />
            </label>
            <label>
              قیمت تنوع: {numberToWords(variant.price)} تومان
              <input
                type="number"
                name="price"
                value={variant.price}
                onChange={(e) => handleVariantChange(index, e)}
                style={{ fontFamily: "Vazir-FD-Wol" }}
              />
            </label>
            <label>
              قیمت فروش در تخفیف تنوع: {numberToWords(variant.salePrice)} تومان
              <input
                type="number"
                name="salePrice"
                value={variant.salePrice}
                onChange={(e) => handleVariantChange(index, e)}
                style={{ fontFamily: "Vazir-FD-Wol" }}
              />
            </label>
            <label>
              تاریخ شروع فروش ویژه تنوع:
              <input
                type="date"
                name="saleStartDate"
                value={variant.saleStartDate}
                onChange={(e) => handleVariantChange(index, e)}
              />
            </label>
            <label>
              تاریخ پایان فروش ویژه تنوع:
              <input
                type="date"
                name="saleEndDate"
                value={variant.saleEndDate}
                onChange={(e) => handleVariantChange(index, e)}
              />
            </label>
            <label
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                alignItems: "center",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <input
                type="checkbox"
                name="isActive"
                style={{ width: "20px" }}
                checked={variant.isActive}
                onChange={(e) =>
                  handleVariantChange(index, {
                    target: { name: "isActive", value: e.target.checked },
                  })
                }
              />
              <span style={{ width: "180px", marginRight: "0px" }}>
                آیا این تنوع فعال است؟
              </span>
            </label>
            <button
              type="button"
              onClick={() => removeVariant(index)}
              style={{
                fontFamily: "Vazir",
                backgroundColor: "#f44336",
                color: "#fff",
                width: "100%",
              }}
            >
              حذف تنوع
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={addVariant}
          style={{ fontFamily: "Vazir" }}
        >
          افزودن تنوع
        </button>
        <button
          type="submit"
          style={{ fontFamily: "Vazir", backgroundColor: "#4CAF50" }}
        >
          ذخیره
        </button>
      </form>
    </div>
  );
}
