import axios from "axios";

export const createOrder = async (
  addressId,
  deliveryService,
  userAccessToken
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/order/new`,
      {
        addressId,
        deliveryService,
      },
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
