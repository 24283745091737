import React from "react";
import "./AboutFooter.css";

export default function AboutFooter() {
  return (
    <div className="AboutFooter">
      <div className="box-AboutFooter">
        <div className="item-box-AboutFooter">
          <p className="item-text-AboutFooter">
            فروشگاه اینترنتی PS4Tehransar یکی از معتبر ترین فروشگاه های مختص
            فروش بازی و لوازم جانبی انواع کنسول ها می باشد که سابقه و فعالیت
            چندین ساله دارد.
          </p>
        </div>
        <div className="item-box-AboutFooter">
          <div className="lines-box-AboutFooter">
            <div className="test-image-AboutFooter">
              <a
                referrerpolicy="origin"
                target="_blank"
                href="https://trustseal.enamad.ir/?id=409116&code=VUn65m4l41SlMfMqDfYjAGb2wjZOoQaz"
                style={{
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  referrerpolicy="origin"
                  src="https://trustseal.enamad.ir/logo.aspx?id=409116&Code=VUn65m4l41SlMfMqDfYjAGb2wjZOoQaz"
                  alt=""
                  code="VUn65m4l41SlMfMqDfYjAGb2wjZOoQaz"
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "13px",
                  }}
                />
              </a>
            </div>
            <div className="test-image-AboutFooter">
              <div className="zarinpal">
                <a
                  referrerpolicy="origin"
                  target="_blank"
                  href="https://www.zarinpal.com/trustPage/www.ps4tehransar.ir"
                >
                  <img
                    referrerpolicy="origin"
                    src="https://cdn.zarinpal.com/badges/trustLogo/1.png"
                    alt="پرداخت امن زرین پال"
                    style={{
                      width: "auto",
                      height: "100%",
                    }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
