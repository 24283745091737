import React, { useState, useEffect } from "react";
import { fetchAdminDetails } from "../../scripts/admin/adminHome";
import useStore from "../../stores/store";
import "./AdminHome.css"; // Assuming you will create a CSS file for styling
import { toast } from "react-toastify";

export default function AdminHome() {
  const [details, setDetails] = useState({});
  const userAccessToken = useStore((state) => state.userAccessToken);

  useEffect(() => {
    const getDetails = async () => {
      const token = userAccessToken;
      const response = await fetchAdminDetails(token);
      if (response.success) {
        setDetails(response.data);
      } else {
        toast.error("خطا در دریافت اطلاعات داشبورد");
      }
    };

    getDetails();
  }, [userAccessToken]);

  return (
    <div className="admin-home">
      <div className="detail-box">
        <h3>تعداد سفارشات</h3>
        <p>{details.totalOrders}</p>
      </div>
      <div className="detail-box">
        <h3>تعداد کاربران</h3>
        <p>{details.totalUsers}</p>
      </div>
      <div className="detail-box">
        <h3>تعداد محصولات</h3>
        <p>{details.totalProducts}</p>
      </div>
      <div
        className={`detail-box ${details.smsCredit < 100 ? "low-credit" : ""}`}
      >
        <h3>اعتبار پیامک</h3>
        <p>{details.smsCredit}</p>
      </div>
    </div>
  );
}
