import axios from "axios";

export const getOrdersList = async (userAccessToken) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/order/list`,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
