import React from "react";
import "./ProfileDashboard.css";
import LabelAndInputDashboard from "./LabelAndInputDashboard";

export default function ProfileDashboard({
  userDetails,
  onUpdateUserDetails,
  onUserDetailsChange,
}) {
  const handleSave = () => {
    onUpdateUserDetails(userDetails);
  };

  const handleInputChange = (field) => (event) => {
    onUserDetailsChange(field, event.target.value);
  };

  return (
    <div className="container-ProfileDashboard">
      <LabelAndInputDashboard
        label="نام"
        placeholder="نام"
        type="text"
        value={userDetails.name}
        onChange={handleInputChange("name")}
      />
      <LabelAndInputDashboard
        label="نام خانوادگی"
        placeholder="نام خانوادگی"
        type="text"
        value={userDetails.lastName}
        onChange={handleInputChange("lastName")}
      />
      <LabelAndInputDashboard
        label="ایمیل"
        placeholder="ایمیل"
        type="email"
        value={userDetails.email}
        onChange={handleInputChange("email")}
      />
      <LabelAndInputDashboard
        label="تلفن همراه"
        placeholder="تلفن همراه"
        type="number"
        value={userDetails.phoneNumber}
        onChange={handleInputChange("phoneNumber")}
      />
      <LabelAndInputDashboard
        label="تاریخ تولد"
        placeholder="مثال: 1392/05/27"
        type="text"
        value={userDetails.birthdate}
        onChange={handleInputChange("birthdate")}
      />

      <button className="save-Button-ProfileDashboard" onClick={handleSave}>
        <p>ذخیره</p>
      </button>
    </div>
  );
}
