import React from "react";
import "./CategoryBoxImage.css";
import { Link, useNavigate } from "react-router-dom";

export default function CategoryBoxImage({ imageSrc, link }) {
  const navigate = useNavigate();

  return (
    <Link className="CategoryBoxImage" to={`${link}`}>
      <img
        className="image-CategoryBoxImage"
        src={imageSrc}
        alt="Category Box"
      />
    </Link>
  );
}
