import axios from "axios";

export const updateUserDetails = async (userAccessToken, userDetails) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_SERVER_URL}/api/users/update`,
      {
        name: userDetails.name,
        lastName: userDetails.lastName,
        email: userDetails.email,
        birthdate: userDetails.birthdate, // Convert to desired format
      },
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
