import React from "react";
import "./LabelAndInputDashboard.css";

export default function LabelAndInputDashboard({
  label,
  placeholder,
  type,
  value,
  onChange,
}) {
  return (
    <div className="LabelAndInputDashboard">
      <label className="label-LabelAndInputDashboard">{label}</label>
      <input
        className="input-LabelAndInputDashboard"
        type={type}
        placeholder={placeholder}
        disabled={type === "number" ? true : false}
        value={value}
        onChange={onChange}
      />
    </div>
  );
}
