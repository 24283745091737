import axios from "axios";

export const sendCode = async (phoneNumber) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/users/send-code`,
      {
        phoneNumber,
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
