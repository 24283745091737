import React from "react";
import "./BuyBoxProduct.css";
import formatPrice from "../../scripts/formatPrice";
import useStore from "../../stores/store";
import { toast } from "react-toastify";

export default function BuyBoxProduct({
  offerLabel,
  productPrice,
  productNewPrice,
  productId,
  variantId,
  variantName,
  productImage,
  productName,
  productStock,
  selectedVariant, // Add selectedVariant to props
}) {
  const addToCart = useStore((state) => state.addToCart);

  const calculateDiscountPercent = (originalPrice, newPrice) => {
    return Math.round(((originalPrice - newPrice) / originalPrice) * 100);
  };

  const addToCartHandler = async () => {
    const product = {
      id: productId,
      variantId: variantId,
      variantName: variantName,
      quantity: 1,
      productPrice: productNewPrice || productPrice,
      imageObject: productImage,
      name: productName,
    };
    await addToCart(product);
    toast.success("محصول به سبد خرید اضافه شد");
  };

  return (
    <div className="boxes-main-BuyBoxProduct">
      <div className="buy-box-BuyBoxProduct">
        <div className="seller-box-BuyBoxProduct">
          <p className="seller-title-BuyBoxProduct">فروشنده</p>
          <div className="seller-info-BuyBoxProduct">
            <img
              className="seller-image-BuyBoxProduct"
              src="/assets/icons/market.png"
              alt="Seller"
            />
            <p className="seller-name-BuyBoxProduct">پی اس فور تهرانسر</p>
            <p className="seller-attribute-BuyBoxProduct">منتخب</p>
          </div>
        </div>
        <div className="divider-line-BuyBoxProduct"></div>
        {offerLabel ? (
          <div className="price-box-BuyBoxProduct">
            <p className="price-offer-text-BuyBoxProduct">
              تخفیف ویژه{" "}
              <p className="price-offer-percent-BuyBoxProduct">
                {calculateDiscountPercent(productPrice, productNewPrice)}%
              </p>
            </p>
            <div className="main-price-box-BuyBoxProduct">
              <p className="main-price-text-BuyBoxProduct">
                {formatPrice(productPrice)} تومان
              </p>
              <img
                className="main-price-arrow-BuyBoxProduct"
                src="/assets/icons/arrows-right-think.png"
                alt="Arrow"
              />
              <p className="new-price-text-BuyBoxProduct">
                {formatPrice(productNewPrice)} تومان
              </p>
            </div>
            {selectedVariant?.stock === 0 ||
            (!selectedVariant && productStock === 0) ? (
              <button
                className="add-to-cart-box-BuyBoxProduct"
                style={{ background: "#c0c0c0" }}
                disabled
              >
                <p className="new-price-BuyBoxProduct">ناموجود</p>
              </button>
            ) : (
              <button
                className="add-to-cart-box-BuyBoxProduct"
                onClick={addToCartHandler}
              >
                <p className="new-price-BuyBoxProduct">افزودن به سبد خرید</p>
              </button>
            )}
            {/* <p className="time-offer-box-BuyBoxProduct">
          <div className="time-offer-BuyBoxProduct">
            <p className="time-texts-offer-remain-BuyBoxProduct">16</p>{" "}
            <p className="time-texts-offer-remain-BuyBoxProduct">دقیقه</p>
          </div>
          <div className="time-offer-BuyBoxProduct">
            <p className="time-texts-offer-remain-BuyBoxProduct">3</p>{" "}
            <p className="time-texts-offer-remain-BuyBoxProduct">ساعت</p>
          </div>
          <div className="time-offer-BuyBoxProduct">
            <p className="time-texts-offer-remain-BuyBoxProduct">9</p>{" "}
            <p className="time-texts-offer-remain-BuyBoxProduct">روز</p>
          </div>
        </p> */}
          </div>
        ) : (
          <div className="price-box-normal-BuyBoxProduct">
            <div className="main-price-box2-BuyBoxProduct">
              <p className="price-text-BuyBoxProduct">
                {formatPrice(productPrice)} تومان
              </p>
              <p className="price-subtitle-BuyBoxProduct">
                بهترین قیمت در 7 روز گذشته
              </p>
            </div>
            {selectedVariant?.stock === 0 ||
            (!selectedVariant && productStock === 0) ? (
              <button
                className="add-to-cart-box-BuyBoxProduct"
                style={{ background: "#c0c0c0" }}
                disabled
              >
                <p className="new-price-BuyBoxProduct">ناموجود</p>
              </button>
            ) : (
              <button
                className="add-to-cart-box-BuyBoxProduct"
                style={{
                  background: "linear-gradient(to right, #4e9, #1b6)",
                }}
                onClick={addToCartHandler}
              >
                <p className="new-price-BuyBoxProduct">افزودن به سبد خرید</p>
              </button>
            )}
          </div>
        )}

        <div className="divider-line-BuyBoxProduct"></div>
        <div className="security-box-BuyBoxProduct">
          <p className="security-text-BuyBoxProduct">
            گارانتی اصالت و سلامت فیزیکی کالا
          </p>
          <img
            className="security-image-BuyBoxProduct"
            src="/assets/icons/security.png"
            alt="Security"
          />
        </div>
        <div className="delivery-box-BuyBoxProduct">
          <p className="security-text-BuyBoxProduct">ارسال در سریع ترین زمان</p>
          <img
            className="security-image-BuyBoxProduct"
            src="/assets/icons/fast-delivery.png"
            alt="Fast delivery"
          />
        </div>
      </div>
    </div>
  );
}
