import React, { useState } from "react";
import "./AddressesDashboard.css";
import { toast } from "react-toastify";
let iranCity = require("iran-city");

export default function AddressesDashBoard({
  addressesList,
  handleUpdateAddress,
  handleRemoveAddress,
  handleCreateAddress, // Add handleCreateAddress prop
}) {
  const [editMode, setEditMode] = useState(null);
  const [editedAddress, setEditedAddress] = useState({});
  const [stateCities, setStateCities] = useState([]);
  const [newAddress, setNewAddress] = useState(null); // State for new address
  let allStates = iranCity.allProvinces();

  const handleEditClick = (addressId) => {
    setEditMode(addressId);
    const address = addressesList.find((addr) => addr.addressId === addressId);
    setEditedAddress(address);
    setStateCities(iranCity.citiesOfProvince(address.stateId));
  };

  const handleSaveClick = () => {
    handleUpdateAddress(editMode, editedAddress);
    setEditMode(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (editMode === "new") {
      setNewAddress((prev) => ({ ...prev, [name]: value }));
      if (name === "stateId") {
        const selectedState = allStates.find(
          (state) => state.id === Number(value)
        );
        const cities = iranCity.citiesOfProvince(Number(value));
        setStateCities(cities);
        setNewAddress((prev) => ({
          ...prev,
          city: "",
          state: selectedState ? selectedState.name : "",
        }));
      }
    } else {
      setEditedAddress((prev) => ({ ...prev, [name]: value }));
      if (name === "stateId") {
        const selectedState = allStates.find(
          (state) => state.id === Number(value)
        );
        const cities = iranCity.citiesOfProvince(Number(value));
        setStateCities(cities);
        setEditedAddress((prev) => ({
          ...prev,
          city: "",
          state: selectedState ? selectedState.name : "",
        }));
      }
    }
  };

  const handleNewAddressClick = () => {
    setNewAddress({
      addressId: "new",
      name: "",
      lastName: "",
      stateId: "",
      city: "",
      streetAddress: "",
      postalCode: "",
      phoneNumber: "",
    });
    setEditMode("new");
  };

  const handleSaveNewAddress = async () => {
    const {
      name,
      lastName,
      stateId,
      city,
      streetAddress,
      postalCode,
      phoneNumber,
    } = newAddress;
    if (
      !name ||
      !lastName ||
      !stateId ||
      !city ||
      !streetAddress ||
      !postalCode ||
      !phoneNumber
    ) {
      toast.error("لطفا تمام فیلدها را پر کنید.");
      return;
    }
    await handleCreateAddress(newAddress);
    setNewAddress(null);
    setEditMode(null);
  };

  const handleCancelNewAddress = () => {
    setNewAddress(null);
    setEditMode(null);
  };

  return (
    <div className="container-AddressesDashBoard">
      <button
        className="new-addresses-btn-AddressesDashboard"
        onClick={handleNewAddressClick}
      >
        آدرس جدید
      </button>
      {newAddress && (
        <div key={newAddress.addressId} className="box-AddressesDashboard">
          <div className="top-box-AddressesDashboard">
            <input
              type="text"
              name="name"
              value={newAddress.name}
              onChange={handleChange}
              className="inputs-editable-AddressesDashboard"
              placeholder="نام"
              required
            />
            <input
              type="text"
              name="lastName"
              value={newAddress.lastName}
              onChange={handleChange}
              className="inputs-editable-AddressesDashboard"
              placeholder="نام خانوادگی"
              required
            />
            <select
              name="stateId"
              value={newAddress.stateId}
              onChange={handleChange}
              className="inputs-editable-AddressesDashboard"
              style={{ height: "30px" }}
              required
            >
              <option value="">انتخاب استان</option>
              {allStates.map((state) => (
                <option key={state.id} value={state.id}>
                  {state.name}
                </option>
              ))}
            </select>
            <select
              name="city"
              value={newAddress.city}
              onChange={handleChange}
              className="inputs-editable-AddressesDashboard"
              style={{ height: "30px" }}
              required
            >
              <option value="">انتخاب شهر</option>
              {stateCities.map((city) => (
                <option key={city.id} value={city.name}>
                  {city.name}
                </option>
              ))}
            </select>
            <input
              type="text"
              name="streetAddress"
              value={newAddress.streetAddress}
              onChange={handleChange}
              className="inputs-editable-AddressesDashboard"
              placeholder="آدرس"
              required
            />
          </div>
          <div className="bottem-box-AddressesDashboard">
            <div className="left-box-AddressesDashboard">
              <div
                className="edit-btn-AddressesDashboard"
                onClick={handleSaveNewAddress}
                style={{
                  background:
                    "linear-gradient(90deg, rgba(106, 194, 21, 1) 30%, rgba(19, 134, 8, 1) 100%)",
                }}
              >
                ذخیره
              </div>
              <div
                className="remove-btn-AddressesDashboard"
                onClick={handleCancelNewAddress}
              >
                لغو
              </div>
            </div>
            <div className="right-box-AddressesDashboard">
              <div className="right-box-item-AddressesDashboard">
                <img
                  className="icon-AddressesDashboard"
                  src="/assets/icons/postbox.png"
                  alt="postbox"
                />
                <p className="label-AddressesDashboard">کد پستی:</p>
                <input
                  type="text"
                  name="postalCode"
                  value={newAddress.postalCode}
                  onChange={handleChange}
                  className="inputs-editable-AddressesDashboard"
                  placeholder="کد پستی"
                  style={{ maxWidth: "60%" }}
                  required
                />
              </div>
              <div className="right-box-item-AddressesDashboard">
                <img
                  className="icon-AddressesDashboard"
                  src="/assets/icons/call.png"
                  alt="call"
                />
                <p className="label-AddressesDashboard">شماره موبایل:</p>
                <input
                  type="text"
                  name="phoneNumber"
                  value={newAddress.phoneNumber}
                  onChange={handleChange}
                  className="inputs-editable-AddressesDashboard"
                  placeholder="شماره موبایل"
                  style={{ maxWidth: "60%" }}
                  required
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {addressesList.map((address) => (
        <div key={address.addressId} className="box-AddressesDashboard">
          <div className="top-box-AddressesDashboard">
            {editMode === address.addressId ? (
              <>
                <input
                  type="text"
                  name="name"
                  value={editedAddress.name}
                  onChange={handleChange}
                  className="inputs-editable-AddressesDashboard"
                  placeholder="نام"
                />
                <input
                  type="text"
                  name="lastName"
                  value={editedAddress.lastName}
                  onChange={handleChange}
                  className="inputs-editable-AddressesDashboard"
                  placeholder="نام خانوادگی"
                />
                <select
                  name="stateId"
                  value={editedAddress.stateId}
                  onChange={handleChange}
                  className="inputs-editable-AddressesDashboard"
                  style={{ height: "30px" }}
                >
                  <option value="">انتخاب استان</option>
                  {allStates.map((state) => (
                    <option key={state.id} value={state.id}>
                      {state.name}
                    </option>
                  ))}
                </select>
                <select
                  name="city"
                  value={editedAddress.city}
                  onChange={handleChange}
                  className="inputs-editable-AddressesDashboard"
                  style={{ height: "30px" }}
                >
                  <option value="">انتخاب شهر</option>
                  {stateCities.map((city) => (
                    <option key={city.id} value={city.name}>
                      {city.name}
                    </option>
                  ))}
                </select>
                <input
                  type="text"
                  name="streetAddress"
                  value={editedAddress.streetAddress}
                  onChange={handleChange}
                  className="inputs-editable-AddressesDashboard"
                  placeholder="آدرس"
                />
              </>
            ) : (
              <>
                <p className="reciver-name-AddressesDashboard">
                  {address.name} {address.lastName}
                </p>
                <p className="address-AddressesDashboard">
                  {address.state} ، {address.city} ، {address.streetAddress}
                </p>
              </>
            )}
          </div>
          <div className="bottem-box-AddressesDashboard">
            <div className="left-box-AddressesDashboard">
              {editMode === address.addressId ? (
                <div
                  className="edit-btn-AddressesDashboard"
                  onClick={handleSaveClick}
                  style={{
                    background:
                      "linear-gradient(90deg, rgba(106, 194, 21, 1) 30%, rgba(19, 134, 8, 1) 100%)",
                  }}
                >
                  ذخیره
                </div>
              ) : (
                <button
                  className="edit-btn-AddressesDashboard"
                  onClick={() => handleEditClick(address.addressId)}
                >
                  ویرایش
                </button>
              )}
              <button
                className="remove-btn-AddressesDashboard"
                onClick={() => handleRemoveAddress(address.addressId)}
              >
                حذف
              </button>
            </div>
            <div className="right-box-AddressesDashboard">
              <div className="right-box-item-AddressesDashboard">
                <img
                  className="icon-AddressesDashboard"
                  src="/assets/icons/postbox.png"
                  alt="postbox"
                />
                <p className="label-AddressesDashboard">کد پستی:</p>
                {editMode === address.addressId ? (
                  <input
                    type="text"
                    name="postalCode"
                    value={editedAddress.postalCode}
                    onChange={handleChange}
                    className="inputs-editable-AddressesDashboard"
                    placeholder="کد پستی"
                    style={{ maxWidth: "60%" }}
                  />
                ) : (
                  <p className="text-AddressesDashboard">
                    {address.postalCode}
                  </p>
                )}
              </div>
              <div className="right-box-item-AddressesDashboard">
                <img
                  className="icon-AddressesDashboard"
                  src="/assets/icons/call.png"
                  alt="call"
                />
                <p className="label-AddressesDashboard">شماره موبایل:</p>
                {editMode === address.addressId ? (
                  <input
                    type="text"
                    name="phoneNumber"
                    value={editedAddress.phoneNumber}
                    onChange={handleChange}
                    className="inputs-editable-AddressesDashboard"
                    placeholder="شماره موبایل"
                    style={{ maxWidth: "60%" }}
                  />
                ) : (
                  <p className="text-AddressesDashboard">
                    {address.phoneNumber}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
