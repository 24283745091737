import React from "react";
import "./Advantages.css";

export default function Advantages() {
  return (
    <div className="Advantages">
      <div className="box-Advantages">
        <div className="item-box-Advantages">
          <img
            className="item-image-Advantages"
            src="/assets/icons/money-back-guarantee.png"
            alt="Money back guarantee"
          />
          <p className="item-text-Advantages">ضمانت بازگشت 100% پول</p>
        </div>
        <div className="item-box-Advantages">
          <img
            className="item-image-Advantages"
            src="/assets/icons/secure-transactions.png"
            alt="Secure transactions"
          />
          <p className="item-text-Advantages">پرداخت امن و مطمئن</p>
        </div>
        <div className="item-box-Advantages">
          <img
            className="item-image-Advantages"
            src="/assets/icons/delivery.png"
            alt="Fast delivery"
          />
          <p className="item-text-Advantages">ارسال سریع</p>
        </div>
      </div>
    </div>
  );
}
