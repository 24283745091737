import React from "react";
import "./MainDetailsProduct.css";
import formatPrice from "../../scripts/formatPrice";
import { Link } from "react-router-dom";

export default function MainDetailsProduct({
  productName,
  productDescription,
  productCategories, // Update prop name
  variants,
  selectedVariant,
  onVariantChange,
}) {
  return (
    <div className="boxes-main-MainDetailsProduct">
      <h1 className="main-title-MainDetailsProduct">خرید {productName}</h1>
      <div className="divider-line-mid-MainDetailsProduct"></div>
      <p className="main-description-MainDetailsProduct">
        {productDescription}
      </p>
      <div className="variants-container-MainDetailsProduct">
        {variants.map((variant) => (
          <label key={variant._id}>
            <input
              type="radio"
              name="variant"
              value={variant._id}
              checked={selectedVariant._id === variant._id}
              onChange={() => onVariantChange(variant)}
            />
            {variant.name} -{" "}
            {variant.salePrice && (
              <span style={{ textDecoration: "line-through" }}>
                {formatPrice(variant.price)} تومان
              </span>
            )}{" "}
            {variant.salePrice
              ? formatPrice(variant.salePrice)
              : formatPrice(variant.price)}{" "}
            تومان
            {variant.stock === 0 && (
              <span style={{ color: "red" }}> ناموجود</span>
            )}
          </label>
        ))}
      </div>
      <p className="main-category-MainDetailsProduct">
        دسته بندی:{" "}
        {productCategories.map((category, index) => (
          <>
            <Link to={`/products?category=${category}`}>
              <span
                key={index}
                className="main-category-span-MainDetailsProduct"
              >
                {category}
              </span>
            </Link>
            <span> {index < productCategories.length - 1 && ", "}</span>
          </>
        ))}
      </p>
    </div>
  );
}
