import React from "react";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";

export default function NotFound() {
  return (
    <>
      <Navbar />
      <div
        style={{
          minHeight: "500px",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h1>404 Not Found</h1>
        <br />
        <h2>صفحه مورد نظر یافت نشد</h2>
      </div>
      <Footer />
    </>
  );
}
