import React from "react";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import "./AboutUs.css";

export default function AboutUs() {
  return (
    <>
      <Navbar />
      <div className="container-AboutUs">
        <section id="about-us">
          <div className="content-AboutUs">
            <h1 className="h1-AboutUs">درباره ما</h1>
            <p className="p-AboutUs">
              فروشگاه اینترنتی <strong>PS4Tehransar</strong> یکی از معتبرترین
              فروشگاه‌های تخصصی فروش بازی‌ها و لوازم جانبی انواع کنسول‌های بازی
              است که با سابقه‌ای چندین ساله، افتخار خدمت‌رسانی به مشتریان عزیز
              را دارد. هدف ما ارائه محصولات باکیفیت و خدماتی مطمئن برای
              علاقه‌مندان به دنیای بازی‌های ویدیویی است.
            </p>
            <h2 className="h2-AboutUs">چرا PS4Tehransar؟</h2>
            <ul className="ul-AboutUs">
              <li>
                <strong>اصالت کالاها:</strong> تمامی محصولات ارائه شده دارای
                ضمانت اصالت و کیفیت هستند.
              </li>
              <li>
                <strong>تجربه و تخصص:</strong> با سال‌ها تجربه در زمینه بازی‌های
                ویدیویی، تیم ما بهترین مشاوره و خدمات را ارائه می‌دهد.
              </li>
              <li>
                <strong>خرید آسان:</strong> امکان خرید آنلاین با تحویل سریع درب
                منزل برای تمام نقاط کشور.
              </li>
            </ul>
            <h2 className="h2-AboutUs">خدمات و گارانتی</h2>
            <p className="p-AboutUs">
              تمامی کنسول‌ها و محصولات فروشگاه با ضمانت به فروش می‌رسند. مشتریان
              می‌توانند از خدمات ویژه‌ای مانند مشاوره تخصصی، آپدیت‌های
              نرم‌افزاری و سخت‌افزاری، و گارانتی تعمیر یا تعویض کالا بهره‌مند
              شوند.
            </p>
            <h2 className="h2-AboutUs">تحویل سریع و پشتیبانی حرفه‌ای</h2>
            <p className="p-AboutUs">
              با تیم مجرب ما، سفارش‌ها در سریع‌ترین زمان ممکن به دست مشتریان
              می‌رسند. ساکنین تهران امکان دریافت کالا با استفاده از پیک در چند
              ساعت را دارند و ساکنین سایر استان‌ها نیز می‌توانند با استفاده از
              خدمات پست پیشتاز سفارش خود را دریافت کنند.
            </p>

            <h2 className="h2-AboutUs">تماس با ما</h2>
            <p className="p-AboutUs">
              برای اطلاعات بیشتر یا طرح هرگونه سوال، پیشنهاد یا انتقاد می‌توانید
              از راه‌های ارتباطی زیر با ما در تماس باشید:
            </p>
            <ul className="contact-AboutUs">
              <li>
                ایمیل:{" "}
                <a href="mailto:info@ps4tehransar.ir" className="a-AboutUs">
                  info@ps4tehransar.ir
                </a>
              </li>
              <li>شماره تماس: 09129578265</li>
              <li>واتس‌اپ و تلگرام: 09129578265</li>
              <li>
                آدرس دفتر مرکزی: تهران ، تهرانسر ، جنب میدان کماللملک ، پاساژ
                الهام ، طبقه اول ، پلاک 1
              </li>
            </ul>
            <p className="p-AboutUs">
              فروشگاه PS4Tehransar همواره پذیرای پیشنهادات و انتقادات شما عزیزان
              است. با ما همراه باشید تا تجربه‌ای متفاوت و لذت‌بخش از خرید بازی و
              لوازم جانبی را تجربه کنید.
            </p>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}
