let categoryData = [
  [
    {
      cols: 2,
      imageSrc: ["/assets/images/test5.jpg", "/assets/images/test6.jpg"],
      links: [
        "/products?category=کنترلر-و-DualSense-برای-PS5",
        "/products?category=هارد-و-حافظه-جانبی-PS5",
      ],
    },
    {
      cols: 1,
      imageSrc: ["/assets/images/test4.jpg"],
      links: ["/products?category=مانیتور-گیمینگ"],
    },
  ],
  [
    {
      cols: 1,
      imageSrc: ["/assets/images/test9.jpg"],
      links: ["/products?category=کنسول-بازی-نینتندو"],
    },
    {
      cols: 2,
      imageSrc: ["/assets/images/test7.jpg", "/assets/images/test8.jpg"],
      links: [
        "/products?category=دیووم-Divoom",
        "/products?category=واقعیت-مجازی-و-VR",
      ],
    },
  ],
  [
    {
      cols: 2,
      imageSrc: ["/assets/images/test10.jpg", "/assets/images/test11.jpg"],
      links: [
        "/products?category=کیبورد-گیمینگ",
        "/products?category=کنترلر-های-ایکس-باکس",
      ],
    },
    {
      cols: 2,
      imageSrc: ["/assets/images/test12.jpg", "/assets/images/test13.jpg"],
      links: [
        "/products?category=مودم-و-روتر-گیمینگ",
        "/products?category=موس-گیمینگ",
      ],
    },
  ],
];

let categoryDataMobiles = [
  [
    {
      cols: 1,
      imageSrc: ["/assets/images/test5.jpg"],
      links: ["/products?category=کنترلر-و-DualSense-برای-PS5"],
    },
  ],
  [
    {
      cols: 1,
      imageSrc: ["/assets/images/test6.jpg"],
      links: ["/products?category=هارد-و-حافظه-جانبی-PS5"],
    },
  ],
  [
    {
      cols: 1,
      imageSrc: ["/assets/images/test4.jpg"],
      links: ["/products?category=مانیتور-گیمینگ"],
    },
  ],
  [
    {
      cols: 1,
      imageSrc: ["/assets/images/test7.jpg"],
      links: ["/products?category=دیووم-Divoom"],
    },
  ],
  [
    {
      cols: 1,
      imageSrc: ["/assets/images/test8.jpg"],
      links: ["/products?category=واقعیت-مجازی-و-VR"],
    },
  ],
  [
    {
      cols: 1,
      imageSrc: ["/assets/images/test9.jpg"],
      links: ["/products?category=کنسول-بازی-نینتندو"],
    },
  ],
  [
    {
      cols: 1,
      imageSrc: ["/assets/images/test10.jpg"],
      links: ["/products?category=کیبورد-گیمینگ"],
    },
  ],
  [
    {
      cols: 1,
      imageSrc: ["/assets/images/test11.jpg"],
      links: ["/products?category=کنترلر-های-ایکس-باکس"],
    },
  ],
  [
    {
      cols: 1,
      imageSrc: ["/assets/images/test12.jpg"],
      links: ["/products?category=مودم-و-روتر-گیمینگ"],
    },
  ],
  [
    {
      cols: 1,
      imageSrc: ["/assets/images/test13.jpg"],
      links: ["/products?category=موس-گیمینگ"],
    },
  ],
];

let categoryDataTablets = [
  [
    {
      cols: 1,
      imageSrc: ["/assets/images/test5.jpg"],
      links: ["/products?category=کنترلر-و-DualSense-برای-PS5"],
    },
    {
      cols: 1,
      imageSrc: ["/assets/images/test6.jpg"],
      links: ["/products?category=هارد-و-حافظه-جانبی-PS5"],
    },
  ],
  [
    {
      cols: 1,
      imageSrc: ["/assets/images/test9.jpg"],
      links: ["/products?category=کنسول-بازی-نینتندو"],
    },
    {
      cols: 1,
      imageSrc: ["/assets/images/test7.jpg"],
      links: ["/products?category=دیووم-Divoom"],
    },
  ],
  [
    {
      cols: 1,
      imageSrc: ["/assets/images/test10.jpg"],
      links: ["/products?category=کیبورد-گیمینگ"],
    },
    {
      cols: 1,
      imageSrc: ["/assets/images/test11.jpg"],
      links: ["/products?category=کنترلر-های-ایکس-باکس"],
    },
  ],
  [
    {
      cols: 1,
      imageSrc: ["/assets/images/test12.jpg"],
      links: ["/products?category=مودم-و-روتر-گیمینگ"],
    },
    {
      cols: 1,
      imageSrc: ["/assets/images/test13.jpg"],
      links: ["/products?category=موس-گیمینگ"],
    },
  ],
];

export { categoryData, categoryDataMobiles, categoryDataTablets };
