import axios from "axios";

export const getProductDetails = async (slug) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/products/detail/${slug}`
    );
    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error("Failed to fetch product details");
    }
  } catch (error) {
    console.error("Error fetching product details:", error);
    return null;
  }
};
